import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import updateDumbledataPage from '../../utilities/DumbleData';
import { inflate } from '../../utilities/uuid';
import { useAsset } from '../../queries/useAsset';
import AssetThumbnail from '../../components/AssetThumbnail/AssetThumbnail';
import { StoreAction, StoreContext } from '../../store/store';

const EReader = lazy(() => import('component-library/ereader'));

export default function EReaderPage() {
  const { id } = useParams();
  const uuid = inflate('r4r_epub_', id) || '';
  const { state, dispatch } = useContext(StoreContext);

  const { data, isLoading } = useAsset(uuid);

  const epub = data?.url;

  useEffect(() => {
    if (!!data) {
      const localURL = localStorage.getItem('previousPageURL') || undefined;
      updateDumbledataPage({
        data,
        baseName: 'eReader',
        siteSection: 'eReader',
        previousPageURL: state?.previousPageURL || localURL
      });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
      if (localURL) {
        localStorage.removeItem('previousPageURL');
      }
    }
  }, [data]);

  // TODO: placeholder for loading state
  if (isLoading) {
    return null;
  }

  if (!epub) {
    return <AssetThumbnail type="ebook" />;
  }

  return id ? (
    <Suspense>
      <EReader
        epub={epub}
        onClosed={() => {
          window.close();
        }}
        renderError={(error: any) => <h1>{error}</h1>}
      />
    </Suspense>
  ) : null;
}
