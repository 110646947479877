import React, { ReactNode, useEffect, useState } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { Markup } from 'interweave';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Accordion.scss';

interface AccordionProps {
  accordionItemIds: string[];
  isOpenInitially?: boolean;
  onValuesChange?: (value: string | string[]) => void;
  children: React.ReactNode;
  defaultIndex?: number;
  defaultIndexes?: string[];
  multiple?: boolean;
}

export interface AccordionItemProps {
  /**
   * Unique id
   */
  title: string;

  /**
   * Hide when disabled
   */
  disabled?: boolean;

  /**
   * Data to be stored by handler
   */
  data?: unknown;

  /**
   * Item.content
   */
  children: ReactNode;
  menuItemRef?: React.Ref<HTMLDivElement>;
  ariaSelected?: boolean;
  id?: string;
  ariaControls?: string;
}

const className = 'accordion-menu';

const Accordion = ({
  isOpenInitially,
  accordionItemIds,
  children,
  onValuesChange,
  defaultIndex,
  defaultIndexes,
  multiple
}: AccordionProps) => {
  const getDefaultValue = () => {
    return isOpenInitially && accordionItemIds ? accordionItemIds[defaultIndex ?? 0] : '';
  };

  const getDefaultValues = () => {
    return isOpenInitially && defaultIndexes !== undefined ? defaultIndexes : accordionItemIds ?? [];
  };

  const [defaultValues, setDefaultValues] = useState<string[]>(getDefaultValues());
  const [defaultValue, setDefaultValue] = useState<string>(getDefaultValue());

  useEffect(() => {
    if (multiple) {
      setDefaultValues(getDefaultValues());
    } else {
      setDefaultValue(getDefaultValue());
    }
  }, [defaultIndex, defaultIndexes]);

  const handleValueChange = (change: string) => {
    setDefaultValue(change);
    onValuesChange?.(change);
  };

  const handleValuesChange = (change: string[]) => {
    setDefaultValues(change);
    onValuesChange?.(change);
  };

  const accordionType = multiple ? 'multiple' : 'single';

  const getAccordion = () => {
    if (accordionType === 'multiple') {
      return (
        <AccordionPrimitive.Root
          type={accordionType}
          value={defaultValues}
          onValueChange={handleValuesChange}
          className={className}
        >
          {children}
        </AccordionPrimitive.Root>
      );
    } else {
      return (
        <AccordionPrimitive.Root
          type={accordionType}
          value={defaultValue}
          onValueChange={handleValueChange}
          collapsible
          className={className}
        >
          {children}
        </AccordionPrimitive.Root>
      );
    }
  };

  return getAccordion();
};

export const AccordionItem = ({
  id,
  title,
  children,
  disabled,
  menuItemRef,
  ariaSelected,
  ariaControls
}: AccordionItemProps) => {
  return (
    <AccordionPrimitive.Item value={id ?? title} className={`${className}__accordion-item`} ref={menuItemRef}>
      <AccordionPrimitive.Header asChild>
        <AccordionPrimitive.Trigger
          id={id}
          className={`${className}__trigger`}
          aria-selected={ariaSelected}
          aria-controls={ariaControls}
        >
          <span className={`${className}__title`}>
            <Markup content={title} tagName="fragment" />
          </span>
          {disabled && (
            <FontAwesomeIcon aria-hidden className={`${className}__chevron`} icon={faChevronDown} />
          )}
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content aria-labelledby={id} className={`${className}__content`}>
        {children}
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
};

export default Accordion;
