import React from 'react'
import './Card.scss'

interface CardProps {
  content: React.ReactNode
  hasRoundedBorders?: boolean
  borderColor?: string
  href?: string
  onSelect?: () => void
}

/**
 * Component for creating a card.
 *
 * @component
 * @param CardProps details about the card
 * @param CardProps.content a HTML element containing content for the card
 * @param CardProps.hasRoundedBorders specifies if the edges of the card are rounded, default is rounded
 * @param CardProps.borderColor a string that specifies the color of the border of the card, default is gray
 * @param CardProps.href makes the card a clickable button if one is passed
 * @param CardProps.onSelect callback function that fires a function when the card is clicked
 *
 * @example
 * <Card
 *         content={<></>}
 *         onSelect={window.alert("card button was clicked")}
 *       />
 *
 * @return a card component displaying elements according to the specifications that are denoted in the props
 */

const Card = ({ content, hasRoundedBorders = true, borderColor, onSelect, href }: CardProps) => {
  const htmlClass = 'card'
  const getElement = (
    content: React.ReactNode,
    className: string,
    testId: string,
    borderColor?: string,
    href?: string,
    onSelect?: () => void
  ): JSX.Element => {
    if (onSelect)
      return (
        <button
          className={className + '-button'}
          data-testid={testId}
          onClick={onSelect}
          style={{ borderColor: borderColor }}
        >
          {content}
        </button>
      )
    if (href)
      return (
        <a
          href={href}
          target={'_blank'}
          className={className + '-a'}
          data-testid={testId}
          style={{ borderColor: borderColor }}
        >
          {content}
        </a>
      )
    else
      return (
        <div className={className} data-testid={testId} style={{ borderColor: borderColor }}>
          {content}
        </div>
      )
  }
  return getElement(
    <div data-testid={htmlClass + '__content'} className={htmlClass + '__content'}>
      {content}
    </div>,
    `${htmlClass}__container${hasRoundedBorders ? '-hasRoundedBorder' : '-squareBorder'}`,
    `${htmlClass}__container${hasRoundedBorders ? '-hasRoundedBorder' : '-squareBorder'}`,
    borderColor,
    href,
    onSelect
  )
}

export default Card
