import Accordion, { AccordionItem } from '../Accordion/Accordion';
import Drawer from '../Drawer/Drawer';
import styles from './ManageAssignmentsDrawer.module.scss';
import Checkbox from '../CheckBox/Checkbox';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import ChevronDown from '../../assets/images/chevron_down.svg';
import SchoolSuccessIcon from '../../assets/images/school-success.svg';
import bookSpread from '../../assets/images/Book-Spread.svg';
import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAssignmentsByText, setAssignmentOfText } from 'api-library';
import { Status, useToast } from '../ToastOutlet/ToastOutlet';
import { Button } from '@scholastic/mockingjay';
import { Link } from 'react-router-dom';
import { emitDirectCall } from '../../utilities/DumbleData';
import { getEnvironment, locations } from 'authentication-library';

interface ManageAssignmentsDrawerProps {
  textId: string;
  // Trigger button with styling
  triggerButton?: React.ReactNode;
  onDrawerChange?: (open: boolean) => void;
  contentId?: string;
}

const mainInstructions =
  'Select the students that should be assigned this text. Assignments will appear on their Ready4Reading Homepage.';
const secondaryInstructions = 'You can remove an assignment at any time by unselecting the checkbox.';

const getLocationUrl = () => {
  const teacherUrls = locations['TEACHER_SDM_CLASSES' as keyof typeof locations];
  let environment = getEnvironment();
  if (environment === 'LOCAL') {
    environment = 'DEV';
  }
  return teacherUrls[environment];
};

const generateAccordionItemHeader = (title: string, expanded: boolean) => {
  const statusText = expanded ? 'Collapse' : 'Expand';
  const chevronClass = `chevron${expanded ? ' rotated' : ''}`;
  return `<span class='classTitle'>
            <img class='schoolSuccess' src='${SchoolSuccessIcon}' alt='grad cap' aria-hidden=true />${title}</span> 
            <span class='collapseOrExpand' aria-hidden=true >${statusText} <span><img class='${chevronClass}' src='${ChevronDown}' alt='arrow' aria-hidden=true /></span>
            </span>`;
};

const areChangesMadeToStudents = (
  currentStudentData: Map<number, boolean>,
  initialData: Map<number, boolean> | undefined
) => {
  let applyActive = false;
  currentStudentData.forEach((value, key) => {
    if (initialData?.get(key) !== value) {
      applyActive = true;
    }
  });
  return applyActive;
};

const getAssignedStudentsFromMap = (studentMap: Map<number, boolean> | undefined) => {
  const assignedStudents: number[] = [];
  if (studentMap) {
    [...studentMap?.entries()].forEach(([key, value]) => {
      if (value) {
        assignedStudents.push(key);
      }
    });
  }
  return assignedStudents;
};

const ManageAssignmentsDrawer = ({
  textId,
  triggerButton,
  onDrawerChange,
  contentId
}: ManageAssignmentsDrawerProps) => {
  const [openItems, setOpenItems] = useState<string[]>([]);
  const [studentIdToOpenState, setStudentIdToOpenState] = useState<Map<number, boolean>>();
  const [applyButtonActive, setApplyButtonActive] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [initialStudentToOpen, setInitialStudentToOpen] = useState<Map<number, boolean>>();

  const sdmCookie = cookies.get('sdm_nav_ctx');
  const userData = JSON.parse(sdmCookie || '{}');
  const { user_id, orgId } = userData;
  const r4rCookie = cookies.get('R4R');
  const r4rData = JSON.parse(r4rCookie || '{}');
  const { currentSchoolYear } = r4rData;

  const initialStudentToAssignedMap = new Map<number, boolean>();
  const initialOpenClasses: string[] = [];
  const classIdsToStudentIds = new Map<string, number[]>();

  const toast = useToast();
  const showToast = (message: string, status: Status) => {
    toast[status](message);
  };

  const queryClient = useQueryClient();

  const { data: returnedClassData } = useQuery({
    queryKey: ['getAssignmentsByText', user_id, orgId, textId, currentSchoolYear],
    queryFn: () => getAssignmentsByText(user_id, orgId, textId, currentSchoolYear)
  });

  if (returnedClassData?.results) {
    returnedClassData?.results.forEach((classData) => {
      const classId = String(classData.classId);
      classIdsToStudentIds.set(classId, []);
      classData.students.forEach((student) => {
        if (!initialStudentToAssignedMap.has(student.studentId)) {
          initialStudentToAssignedMap.set(student.studentId, student.assigned);
        }

        classIdsToStudentIds.get(classId)?.push(student.studentId);
        if (student.assigned && !initialOpenClasses.includes(classId)) {
          initialOpenClasses.push(classId);
        }
      });
    });
  }

  if (!initialStudentToOpen && [...initialStudentToAssignedMap.keys()].length !== 0) {
    setInitialStudentToOpen(initialStudentToAssignedMap);
  }

  useEffect(() => {
    if (
      initialStudentToOpen &&
      [...initialStudentToOpen.keys()].length !== 0 &&
      (!studentIdToOpenState || [...studentIdToOpenState.keys()].length === 0)
    ) {
      setOpenItems(initialOpenClasses);
      setStudentIdToOpenState(initialStudentToOpen);
    }
  }, [initialStudentToOpen]);

  const getClassesWithAssignedStudents = () => {
    const assignedClasses: string[] = [];
    classIdsToStudentIds.forEach((students, classId) => {
      for (const student of students) {
        if (studentIdToOpenState?.get(student) && !assignedClasses.includes(classId)) {
          assignedClasses.push(classId);
          break;
        }
      }
    });
    return assignedClasses;
  };

  useEffect(() => {
    if (drawerOpen) {
      setApplyButtonActive(false);
      setOpenItems(getClassesWithAssignedStudents());
    }
  }, [drawerOpen]);

  const toggleStudent = (studentID: number) => {
    //activate student in all classes
    const studentStateData = new Map<number, boolean>(studentIdToOpenState);
    studentStateData?.set(studentID, !studentStateData.get(studentID));
    setStudentIdToOpenState(studentStateData);
    //set apply button if changes have been made
    setApplyButtonActive(areChangesMadeToStudents(studentStateData, initialStudentToOpen));
  };

  const handleAccordionItemChange = (values: string | string[]) => {
    if (Array.isArray(values)) {
      setOpenItems(values);
    } else {
      setOpenItems([values]);
    }
  };

  const { mutate } = useMutation(async (classData: any) => {
    const { data: response } = await setAssignmentOfText(user_id, textId, classData);
    return response;
  });

  const handleDirectCall = () => {
    const assignedStudents = getAssignedStudentsFromMap(studentIdToOpenState);
    emitDirectCall('assign-text', {
      id: textId,
      studentIds: assignedStudents
    });
  };

  const applyChanges = () => {
    const completeData = returnedClassData?.results.map((classData) => {
      const studentList = classData.students.map((student) => {
        return {
          studentId: student.studentId,
          firstName: student.firstName,
          lastName: student.lastName,
          assigned: studentIdToOpenState?.get(student.studentId) ?? student.assigned
        };
      });
      return {
        classId: classData.classId,
        className: classData.className,
        students: studentList
      };
    });

    mutate(completeData, {
      onSuccess: async () => {
        //set new initial
        await queryClient.invalidateQueries({ queryKey: ['getAssignmentsByText'] });
        setApplyButtonActive(false);
        setInitialStudentToOpen(studentIdToOpenState);
        setOpenItems(getClassesWithAssignedStudents());
        setDrawerOpen(false);
        if (onDrawerChange) {
          onDrawerChange(false);
        }
        handleDirectCall();
        showToast('Success! Your assignments have been updated.', 'success');
      },
      onError: (error) => {
        showToast('An error occurred creating this assignment. Please try again.', 'error');
      }
    });
  };

  const cancelChanges = () => {
    setDrawerOpen(false);
    if (onDrawerChange) {
      onDrawerChange(false);
    }
    setApplyButtonActive(false);
    setStudentIdToOpenState(initialStudentToOpen);
  };

  const handleDrawerChange = (drawerState: boolean) => {
    setDrawerOpen(drawerState);
    if (onDrawerChange) {
      onDrawerChange(drawerState);
    }
    queryClient.invalidateQueries({ queryKey: ['getAssignmentsByText'] });
  };

  const openOrExpandAll = (collapseAll: boolean) => {
    if (collapseAll) {
      setOpenItems([]);
    } else {
      setOpenItems([...classIdsToStudentIds.keys()]);
    }
  };

  const accordionOpen = openItems.length > 0;

  const handleAllStudents = (classId: string, emptyOrPartial: boolean) => {
    const studentIdToOpenStateMap = new Map<number, boolean>(studentIdToOpenState);
    if (emptyOrPartial) {
      classIdsToStudentIds.get(classId)?.forEach((studentId: number) => {
        studentIdToOpenStateMap.set(studentId, true);
      });
    } else {
      classIdsToStudentIds.get(classId)?.forEach((studentId: number) => {
        studentIdToOpenStateMap.set(studentId, false);
      });
    }
    setApplyButtonActive(areChangesMadeToStudents(studentIdToOpenStateMap, initialStudentToOpen));
    setStudentIdToOpenState(studentIdToOpenStateMap);
  };

  return (
    <div>
      <Drawer
        title="Manage Assignments"
        mainInstructions={mainInstructions}
        secondaryInstructions={secondaryInstructions}
        triggerButton={triggerButton}
        headerIcon={<img src={bookSpread} />}
        controlled
        applyButtonDisabled={!applyButtonActive}
        onApply={applyChanges}
        onCancel={cancelChanges}
        onDrawerChange={handleDrawerChange}
        openControl={drawerOpen}
        contentId={contentId}
      >
        <div className={styles.manageAssignmentsDrawer}>
          {returnedClassData?.results && returnedClassData?.results.length > 0 && (
            <>
              <Button className={styles.expandCollapseAll} onClick={() => openOrExpandAll(accordionOpen)}>
                <span className={styles.collapseOrExpandContents}>
                  <span className={styles.collapseOrExpandText}>
                    {accordionOpen ? 'Collapse' : 'Expand'} All{' '}
                  </span>
                  <img
                    className={`chevron${accordionOpen ? ' rotated' : ''}`}
                    src={ChevronDown}
                    alt="arrow"
                    aria-hidden
                  />
                </span>
              </Button>
              <Accordion
                accordionItemIds={[...classIdsToStudentIds.keys()]}
                onValuesChange={handleAccordionItemChange}
                defaultIndexes={openItems}
                multiple
                isOpenInitially
              >
                {returnedClassData?.results.map((classData, index) => {
                  const classId = String(classData.classId);

                  const assignedStudents: number[] = [];
                  classIdsToStudentIds.get(classId)?.forEach((studentId: number) => {
                    if (studentIdToOpenState?.get(studentId)) {
                      assignedStudents.push(studentId);
                    }
                  });

                  return (
                    <AccordionItem
                      id={classId}
                      title={generateAccordionItemHeader(
                        classData.className ?? '',
                        [...classIdsToStudentIds.keys()].length === 0 || openItems.includes(classId)
                      )}
                      key={index}
                      aria-describedby={undefined}
                    >
                      <div className={styles.checkboxContainer}>
                        <div className={styles.divider}></div>
                        <Checkbox
                          labelContent="All Students"
                          onCheckChange={() =>
                            handleAllStudents(classId, assignedStudents.length !== classData.students.length)
                          }
                          checkedState={assignedStudents.length > 0}
                          checkBoxId={`all_students_${classData.classId}`}
                          ariaLabel={`${classData.classId}_label`}
                          dataTestId={`checkbox-${classData.classId}`}
                          checkedIcon={
                            assignedStudents.length === classData.students.length ? faCheck : faMinus
                          }
                        />
                        <div className={styles.studentCheckboxes}>
                          {classData.students.map((student) => {
                            const openState = studentIdToOpenState?.get(student.studentId);
                            const classStudentId = `${classData.classId}_${student.studentId}`;
                            const randomIdLabel = `${classStudentId}_label`;
                            return (
                              <Checkbox
                                labelContent={`${student.lastName}, ${student.firstName}`}
                                onCheckChange={() => toggleStudent(student.studentId)}
                                checkedState={openState ?? false}
                                checkBoxId={classStudentId}
                                ariaLabel={randomIdLabel}
                                dataTestId={`checkbox-${student.studentId}`}
                                checkedIcon={faCheck}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </>
          )}
          {returnedClassData?.results.length === 0 && (
            <div className={styles.noClassMessage}>
              It looks like you don't have any classes set up. Please{' '}
              <Link className={styles.newClassLink} to={getLocationUrl()}>
                add a new class
              </Link>{' '}
              and roster your students to get started.
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ManageAssignmentsDrawer;
