import React from 'react'
import { routerType } from '../types/router.types'

const titlePrefix: string = 'Ready 4 Reading:'

const routeTypes: routerType[] = [
  {
    path: '/',
    title: `${titlePrefix} Home`
  },
  {
    path: '/skills',
    title: `${titlePrefix} Skills`
  },
  {
    path: '/module',
    title: `${titlePrefix} Module`
  },
  {
    path: '/system-resources',
    title: `${titlePrefix} System Resources`
  },
  {
    path: '/lesson/:id',
    title: `${titlePrefix} Lesson`
  },
  {
    path: '/lesson/:id/tab/:sequence',
    title: `${titlePrefix} Lesson`
  },
  {
    path: '/ebook',
    title: `${titlePrefix} E-Reader`
  },
  {
    path: '/ebook/:id',
    title: `${titlePrefix} E-Reader`
  },
  {
    path: '/video',
    title: `${titlePrefix} Video Player`
  },
  {
    path: '/video/:id',
    title: `${titlePrefix} Video Player`
  },
  {
    path: '/audio',
    title: `${titlePrefix} Audio Player`
  },
  {
    path: '/audio/:id',
    title: `${titlePrefix} Audio Player`
  }
]

const enum pageRoutes {
  HOME = '/',
  SKILLS = '/skills',
  MODULE = '/module',
  SYSTEM_RESOURCES = '/system-resources',
  LESSON = '/lesson/:id',
  LESSON_TAB = '/lesson/:id/tab/:sequence',
  EREADER = '/ebook',
  EREADER_BOOK = '/ebook/:id',
  VIDEO = '/video',
  VIDEO_PLAYER = '/video/:id',
  AUDIO = '/audio',
  AUDIO_PLAYER = '/audio/:id'
}

const enum routeTypeIndexes {
  HOME = 0,
  SKILLS = 1,
  MODULE = 2,
  SYSTEM_RESOURCES = 3,
  LESSON = 4,
  LESSON_TAB = 5,
  EREADER = 6,
  EREADER_BOOK = 7,
  VIDEO = 8,
  VIDEO_PLAYER = 9,
  AUDIO = 10,
  AUDIO_PLAYER = 11
}

export { routeTypes, routeTypeIndexes, titlePrefix, pageRoutes }
