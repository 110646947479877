import React from 'react';
import './PrimaryLayout.scss';

type PrimaryLayoutProps = {
  children: JSX.Element;
};

export default function PrimaryLayout({ children }: PrimaryLayoutProps) {
  return <div className="primary-layout-main">{children}</div>;
}
