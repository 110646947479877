import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from 'authentication-library';
import { getLessonAssetsBySkill, getLessonAssetsBySkillCategory } from 'api-library';

import * as uuid from '../../utilities/uuid';
import SidebarLayout from '../layouts/SidebarLayout/SidebarLayout';
import LessonsDisplayPanel from '../../components/LessonsDisplayPanel/LessonsDisplayPanel';
import useSkillCategories from '../../queries/useSkillCategories';
import { SidebarMenu } from './SidebarMenu';
import updateDumbledataPage from '../../utilities/DumbleData';
import { StoreAction, StoreContext } from '../../store/store';
import { getModuleIds } from '../../utilities/ModuleUtil';

const Skills = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { categoryId: categoryIdInt, skillId: skillIdInt } = useParams();
  const categoryId = uuid.inflate('r4r_skillcat_', categoryIdInt);
  const skillId = uuid.inflate('r4r_skill_', skillIdInt);

  const { modules } = useAuth();
  // Get Sidebar Menu Data
  const { data: skillCategories } = useSkillCategories();

  // Get lesson assets for a single skill
  const { data: skillLessonData } = useQuery(
    ['get', 'lessonAssetsBySkill', skillId],
    () => {
      return getLessonAssetsBySkill(skillId as string, getModuleIds(modules));
    },
    {
      // only run this query if there is a skillId
      enabled: Boolean(skillId)
    }
  );
  const currentCategoryId = categoryId || skillCategories?.[0]?.uuid;

  // Get lesson assets for an entire skill category
  const { data: skillCategoryLessonData } = useQuery(
    ['get', 'lessonAssetsBySkillCategory', currentCategoryId],
    () => {
      return getLessonAssetsBySkillCategory(currentCategoryId as string, getModuleIds(modules));
    },
    {
      // only run this query if there is no skillId
      enabled: !Boolean(skillId) && Boolean(currentCategoryId)
    }
  );

  const categoryObj = skillCategories?.find((category) => category.uuid === currentCategoryId);
  const category = categoryObj?.name;
  const skill = categoryObj?.skills.find((skill) => skill.uuid === skillId)?.name;
  const lessonAssets = Boolean(skillLessonData) ? skillLessonData : skillCategoryLessonData;
  useEffect(() => {
    const siteSection = 'Skills';
    let baseName;
    if (!!category) {
      baseName = 'Skills - ' + category;
      if (!!skill) {
        baseName += ' - ' + skill;
      }
    }
    if (!!baseName) {
      updateDumbledataPage({ baseName, siteSection, previousPageURL: state?.previousPageURL });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
    }
  }, [category, skill]);
  return (
    <>
      <h1 className="page-title">Browse Skills</h1>
      <SidebarLayout
        sidebar={
          <SidebarMenu
            skillCategories={skillCategories}
            initialActiveCategory={currentCategoryId}
            initialActiveSkill={skillId}
          />
        }
      >
        <LessonsDisplayPanel title={category} lessonAssets={lessonAssets} />
      </SidebarLayout>
    </>
  );
};

export default Skills;
