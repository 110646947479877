import React from 'react';
import './SubHeader.scss';
import ExternalNavLink from '../ExternalNavLink/ExternalNavLink';
import { useAuth } from 'authentication-library';

interface SubHeaderProps {
  children: JSX.Element;
}

/**
 * A subheader with three external links and the option for children
 *
 * @example
 * <SubHeader children={<div>Module Content</div>}/>
 *
 */
const SubHeader = ({ children }: SubHeaderProps) => {
  const className = 'sub-header';
  const { entitlements } = useAuth();
  const getDashboardLink = () => {
    return (
      process.env.REACT_APP_DASHBOARDS_URL && (
        <>
          <ExternalNavLink path={process.env.REACT_APP_DASHBOARDS_URL} label="Data and Groups" />
          <span className={`${className}__external-nav-separator`} aria-hidden>
            |
          </span>
        </>
      )
    );
  };
  return (
    <div className={className}>
      <div className={`${className}__container`}>
        {children}
        <div className={`${className}__external-nav-container`}>
          {entitlements?.includes('R4R_System') && (
            <>
              {getDashboardLink()}
              <ExternalNavLink path={'video/100'} label="Assessments" />
              <span className={`${className}__external-nav-separator`} aria-hidden>
                |
              </span>
            </>
          )}
          {(entitlements?.includes('R4R_System') || entitlements?.includes('R4R_WBTP')) && (
            <ExternalNavLink path={process.env.REACT_APP_STUDENT_HUB_URL!} label="Student Hub" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
