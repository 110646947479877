import type { ImagePathType } from './types'

export const getImageSource = ({
  image_path_type,
  image_path
}: {
  image_path_type: ImagePathType
  image_path: string
  [key: string]: any
}): string => {
  if (image_path_type === 'cloudfront') {
    return `${process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN}/${image_path}`
  }

  return image_path
}
