import { useQuery } from 'react-query'
import { getAsset, getAssetsByTypeIdAndMetadata } from 'api-library'

export function useAsset(uuid: string) {
  return useQuery(['asset', uuid], () => getAsset(uuid), { enabled: !!uuid })
}

export function useAssetByTypeIdAndMetadata(typeId: number, key: string, value: string) {
  return useQuery(['assetByTypeIdAndMetadata', `${typeId}-${key}-${value}`], () => {
    return getAssetsByTypeIdAndMetadata(typeId, key, value)
  })
}
