import Activity from './svg/Activity';
import Assessment from './svg/Assessment';
import Audio from './svg/Audio';
import Downloadable from './svg/Downloadable';
import Ebook from './svg/Ebook';
import Lesson from './svg/Lesson';
import Video from './svg/Video';

import './AssetThumbnail.scss';

export type AssetType = 'activity' | 'assessment' | 'audio' | 'downloadable' | 'ebook' | 'lesson' | 'video';

const thumbnails = new Map<AssetType | undefined, React.ElementType<any>>([
  ['activity', Activity],
  ['assessment', Assessment],
  ['audio', Audio],
  ['downloadable', Downloadable],
  ['ebook', Ebook],
  ['lesson', Lesson],
  ['video', Video]
]);

interface AssetThumbnailProps {
  type?: AssetType;
}

const className = 'asset-thumbnail';

export default function AssetThumbnail({ type }: AssetThumbnailProps) {
  const Thumbnail = thumbnails.get(type) || Activity;

  return <Thumbnail className={className} alt="" aria-hidden />;
}
