import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Markup } from 'interweave';
import type { AssetData } from 'api-library';
import SkillCard from '../SkillCard/SkillCard';
import { deflate } from '../../utilities/uuid';
import './DisplayPanel.scss';
import { emitDirectCall } from '../../utilities/DumbleData';

interface PanelProps {
  color: string;
  header: React.ReactNode;
  data: Array<Pick<AssetData, 'title' | 'uuid'>>;
  borderColor?: string;
  key: string;
  title: string;
}

/**
 * Component for creating a display panel
 *
 * @component
 * @param PanelProps details about the Display Panel
 * @param PanelProps.color specifies the background color of the panel
 * @param PanelProps.header takes in a React element to be displayed as the header
 * @param PanelProps.data an array of objects with the following fields: title, type, id, and optionally a description.
 * Data is used to set the information to be displayed within the panel
 * @param PanelProps.borderColor sets the border color for the cards to be displayed in the panel
 *
 *
 * @example
 * <DisplayPanel
 *    color={"black"}
 *    header={<h1>header</h1>}
 *    data={[{
 *     "title": "ABC",
 *     "uuid": "testabc"
 *   }]}
 *  />
 *
 * @return a component displaying a panel of cards using given data in a grid view
 */

const DisplayPanel = (props: PanelProps) => {
  const navigate = useNavigate();
  const cssClass = 'display-panel';
  return (
    <section
      title={props.title}
      className={`${cssClass}__container`}
      data-testid={cssClass + '__container'}
      style={{ background: props.color }}
    >
      {props.header}
      <ul
        role="lesson-group"
        className={cssClass + '__cards-container'}
        data-testid={cssClass + '__cards-container'}
      >
        {props.data.map(({ title, uuid }) => (
          <SkillCard
            title={<Markup content={title} tagName="fragment" />}
            borderColor={props.borderColor}
            key={uuid}
            onSelect={() => {
              emitDirectCall('skillLessons', { name: title });
              navigate(`/lesson/${deflate(uuid)}`);
            }}
          />
        ))}
      </ul>
    </section>
  );
};

export default DisplayPanel;
