import React, { useState } from 'react';
import { AssetData, EbookAssetData, PdfAssetData, VideoAssetData, AssetType, getAsset } from 'api-library';
import './AssetMenu.scss';
import { Link } from 'react-router-dom';
import { deflate } from '../../utilities/uuid';
import { Markup } from 'interweave';
import { emitDirectCall } from '../../utilities/DumbleData';
import AssignmentDialog from '../AssignmentDialog/AssignmentDialog';
import { useQuery } from 'react-query';

type AssetMenuProps = {
  title: string;
  themeClassName?: string;
};

type MenuSectionProps = AssetData[] | VideoAssetData[] | PdfAssetData[] | EbookAssetData[];
type AssetMenuSectionProps = {
  title: string;
  assetType: string;
  assets: MenuSectionProps;
  moduleUuid?: string;
};
type MenuItemProps = AssetData | VideoAssetData | PdfAssetData | EbookAssetData;
type AssetMenuItemProps = {
  title?: string;
  asset: MenuItemProps;
  assetType: string;
  moduleUuid?: string;
};

/**
 * Asset Menu
 * The Asset Menu component represents a single column menu where users can launch PDFs, Videos, Ebooks, Activities,
 * and other asset types
 *
 * @param {string} title - A title for the Asset Menu
 * @param {string} themeClassName - An optional css class name used for theming the menu.
 *  Currently, the only theme available is `r4r-connections`
 * @param {JSX.Element} children - Child elements to be inserted inside the menu
 *
 * AssetMenu.Section
 * The AssetMenu.Section component is used to render menu items within an HTML section element.
 *
 *
 * @param {string} title - A title for the Asset Menu
 * @param {string} assetType - Defines the type of the asset. Use the AssetType enum from the LessonAPI
 * @param {AssetData[] | VideoAssetData[] | PdfAssetData[] | EbookAssetData[]} assets - A collection of assets
 * @param {string} moduleId - An optional module id that themes the asset menu section when using certain themes such as `r4r-connections`.
 *
 * @example
 *
 * <AssetMenu title="Menu Title" themClassName="r4r-connections">
 *       <AssetMenu.Section title="Section Title" assetType={AssetType.pdf} assets={relatedMaterialSampleData.PDF} moduleId="r4r_module_001"/>
 *</AssetMenu>
 *
 */
export function AssetMenu({ title, themeClassName, children }: React.PropsWithChildren<AssetMenuProps>) {
  const className = 'asset-menu';

  return (
    <aside className={className + (themeClassName ? ` ${className}--` + themeClassName : '')}>
      <div className={`${className}__container`}>
        <h2 className={`${className}__title`}>{title}</h2>
        {children}
      </div>
    </aside>
  );
}

function AssetMenuSection({ title, assets, assetType, moduleUuid }: AssetMenuSectionProps) {
  const className = 'asset-menu__section';
  return (
    <section className={className + (moduleUuid ? ` ${className}-module--` + moduleUuid : '')}>
      <h3 className={`${className}-title`}>{title}</h3>
      {assets.map((asset) => (
        <AssetMenuItem
          key={asset.uuid}
          asset={asset}
          assetType={assetType}
          title={title}
          moduleUuid={moduleUuid}
        />
      ))}
    </section>
  );
}

function AssetMenuItem({ title, asset, assetType, moduleUuid }: AssetMenuItemProps) {
  const className = 'asset-menu__item';
  const [isAssignmentDialogOpen, setAssignmentDialogOpen] = useState<boolean>(false);
  const [assignmentImgUrl, setAssignmentImageUrl] = useState<string | undefined>('');
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentEReader, setAssignmentEreader] = useState<string>('');
  const [assignmentId, setAssignmentId] = useState<string>('');

  let thumbnailUrl: string | undefined;
  if (assetType === AssetType.ebook) {
    const { data: completeAssetData } = useQuery(['getAsset', asset.uuid], () => getAsset(asset.uuid));
    thumbnailUrl = `${process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN}/${completeAssetData?.thumbnailUrl}`;
  }

  const toggleAssignmentDialog = (
    assignmentImgUrl: string | undefined,
    assignmentName: string,
    assignmentEReader: string,
    assignmentId: string
  ) => {
    setAssignmentImageUrl(assignmentImgUrl);
    setAssignmentEreader(assignmentEReader);
    setAssignmentName(assignmentName);
    setAssignmentId(assignmentId);
    setAssignmentDialogOpen(!isAssignmentDialogOpen);
  };

  const openInNewTab = assetType !== AssetType.lesson;

  const getRoute = () => {
    switch (assetType) {
      case AssetType.activity: {
        return `${process.env.REACT_APP_ACTIVITY_PLAYER_URL}?id=${asset.uuid}`;
      }
      case AssetType.ebook: {
        return `/ebook/${deflate(asset.uuid)}`;
      }
      case AssetType.video: {
        return `/video/${deflate(asset.uuid)}`;
      }
      case AssetType.audio: {
        return `/audio/${deflate(asset.uuid)}`;
      }
      case AssetType.lesson: {
        return `/lesson/${deflate(asset.uuid)}`;
      }
      case AssetType.pdf: {
        return (asset as PdfAssetData).filename;
      }
      default:
        return '';
    }
  };

  const handleClick = () => {
    if (assetType === AssetType.ebook || assetType === AssetType.video || assetType === AssetType.audio) {
      window.localStorage.setItem('previousPageURL', window.location.href);
    }
    if (assetType === AssetType.lesson) {
      emitDirectCall('r4rConnections', { name: asset.title });
    } else {
      emitDirectCall('lessonMaterial', { name: asset.title, type: title });
    }
  };

  const generateLinkOrButton = () => {
    if (assetType === AssetType.ebook) {
      return (
        <button
          className={className}
          onClick={() => toggleAssignmentDialog(thumbnailUrl, asset.title, getRoute(), asset.uuid)}
          aria-label={`${title} open assignment dialog`}
        >
          <Markup content={asset.title} />
        </button>
      );
    } else {
      return (
        <>
          {openInNewTab ? (
            <Link
              to={getRoute()}
              className={className}
              onClick={handleClick}
              target="_blank"
              aria-label={`${assetType} ${asset.title}`}
            >
              <Markup content={asset.title} />
            </Link>
          ) : (
            <Link
              to={getRoute()}
              className={className}
              onClick={handleClick}
              aria-label={`${assetType} ${asset.title}`}
            >
              <Markup content={asset.title} />
            </Link>
          )}
        </>
      );
    }
  };

  return (
    <>
      {isAssignmentDialogOpen && (
        <AssignmentDialog
          imgUrl={assignmentImgUrl}
          eReaderUrl={assignmentEReader}
          assignmentName={assignmentName}
          onDialogChange={() => setAssignmentDialogOpen(!isAssignmentDialogOpen)}
          textId={assignmentId}
          activeModule={moduleUuid}
        />
      )}
      {generateLinkOrButton()}
    </>
  );
}

AssetMenu.Section = AssetMenuSection;
