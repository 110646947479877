import React, { PropsWithChildren } from 'react';
import './SidebarLayout.scss';

type SidebarLayoutProps = {
  sidebar: JSX.Element;
  reverseLayout?: boolean;
};

export default function SidebarLayout({
  children,
  sidebar,
  reverseLayout = false
}: PropsWithChildren<SidebarLayoutProps>) {
  const className = 'sidebar-layout';

  const elements = [
    <aside key="aside" className={`${className}-aside`}>
      {sidebar}
    </aside>,
    <div key="main" className={`${className}-main`}>
      {children}
    </div>
  ];

  if (reverseLayout) {
    elements.reverse();
  }

  return (
    <div className={className + (reverseLayout ? ` ${className}--reverse` : '')}>
      {elements.map((element) => element)}
    </div>
  );
}
