import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import MegaMenu from './MegaMenu/MegaMenu';
import './LessonMegaDropdown.scss';
import { Markup } from 'interweave';
import { useAuth } from 'authentication-library';
import * as Dialog from '@radix-ui/react-dialog';
import CloseIcon from '../../assets/images/nav-close.svg';

interface LessonMegaDropdownProps {
  currentLesson: string;
  currentModule: string;
}

/**
 * LessonMegaDropdown component
 *
 * @component
 * @param {string} currentLesson - current Lesson shown on the page
 * @param {string} currentModule - current Module that contains the current lesson
 *
 * @example
 * <LessonMegaDropdown currentLesson={"ABCs"} currentModule={'R4R_WBTP'}/>
 *
 * @return Button that triggers the megamenu when clicked
 */

const LessonMegaDropdown = ({ currentLesson, currentModule }: LessonMegaDropdownProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const className = 'lesson-megamenu-dropdown';
  const { modules } = useAuth();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [dialogPosition, setDialogPosition] = useState({});
  const currentLessonLabel = currentLesson.replaceAll(/(<([^>]+)>)/gi, '');

  const toggleMegaMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      dropdownRef.current?.focus();
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => getDialogPosition());
    getDialogPosition();
  }, [currentLesson]);

  const getDialogPosition = () => {
    const bound = dropdownRef.current?.getBoundingClientRect();
    if (bound) {
      const left = bound.left;
      const top = bound.top + bound.height + 1;
      setDialogPosition({ left: left, top: top });
    }
  };

  return (
    <Dialog.Root data-testid={className} open={isMenuOpen}>
      <Dialog.Trigger asChild>
        <button
          data-testid={`${className}__button`}
          className={`${className}__button`}
          ref={dropdownRef}
          aria-label={`module lesson selection ${currentLessonLabel} selected`}
          onClick={toggleMegaMenu}
          aria-controls={`${className}__content`}
        >
          <Markup className={`${className}__label`} content={currentLesson} />
          <FontAwesomeIcon icon={faChevronDown} style={{ transform: isMenuOpen ? 'rotate(180deg)' : '' }} />
        </button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className={`${className}__overlay`} onClick={toggleMegaMenu} />

        <Dialog.Content
          id={`${className}__content`}
          className={`${className}__content`}
          style={dialogPosition}
          aria-modal={true}
          aria-label="Select lesson by module"
          aria-labelledby={undefined}
          aria-describedby={undefined}
        >
          <MegaMenu
            currentLesson={currentLesson}
            currentModule={currentModule}
            closeFunction={toggleMegaMenu}
            availableModules={modules}
          />
          <Dialog.Close asChild>
            <button
              className={`${className}__close-button`}
              type={'button'}
              data-testid={`${className}__close-button`}
              aria-label={'close menu'}
              onClick={toggleMegaMenu}
            >
              <img src={CloseIcon} alt={'X'} aria-hidden />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default LessonMegaDropdown;
