import React, { PropsWithChildren } from 'react'
import PaginationNavigation from './PaginationNavigation/PaginationNavigation'
import './PaginatedGrid.scss'

interface PaginatedGridProps {
  currentPage: number
  totalPages: number
  changePage: (pageNumber: number) => void
}

/**
 * PaginatedGrid component
 *
 * @component
 * @param {Array} gridItems - array of JSON objects that will be displayed
 * @param {number} currentPage - current page on tab
 * @param {number} totalPages - maximum pages to be shown
 * @param {function} incrementPage - function to control navigating to next page
 * @param {decrementPage} - function to control navigating to previous page
 *
 * @example
 * <PaginatedGrid
 *     decrementPage={decrementPage}
 *     incrementPage={incrementPage}
 *     currentPage={1}
 *     totalPages={5}
 *    />
 *
 * @return a Grid that displays paginated data from individual page
 */

const PaginatedGrid = (props: PropsWithChildren<PaginatedGridProps>) => {
  const className = 'paginated-grid'

  return (
    <div className={className} data-testid={className}>
      {props.children}
      <PaginationNavigation
        totalPages={props.totalPages}
        currentPage={props.currentPage}
        changePage={props.changePage}
      />
    </div>
  )
}

export default PaginatedGrid
