import PrimaryLayout from '../layouts/PrimaryLayout/PrimaryLayout';
import { CategoryTab, Tab } from '../../components/CategoryTab/CategoryTab';
import { ModuleTile } from '../../components/ModuleTile/ModuleTile';
import './Module.scss';
import { AssetType as AssetTypeName, getAssetsByModule, ModuleId } from 'api-library';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from '../../components/Select/Select';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import PaginatedGrid from '../../components/PaginatedGrid/PaginatedGrid';
import { ResourcePanel, ResourcePanelItem } from '../../components/ResourcePanel/ResourcePanel';
import { AssetType } from '../../components/AssetThumbnail/AssetThumbnail';
import { useAuth } from 'authentication-library';
import { ProductCode, ProductCodeEnum } from '../../types/authentication.types';
import useModule from '../../queries/useModule';
import { deflate } from '../../utilities/uuid';
import updateDumbledataPage, { emitDirectCall } from '../../utilities/DumbleData';
import FilterByDropdown from '../../components/FilterByDropdown/FilterByDropdown';
import { StoreAction, StoreContext } from '../../store/store';
import { WBFullName, SRDFullName, RTKFullName } from './ModuleConstants';
import FilterByAccordionDropdown from '../../components/FilterByAccordionDropdown/FilterByAccordionDropdown';
import AssignmentDialog from '../../components/AssignmentDialog/AssignmentDialog';

const allModules = [
  ProductCodeEnum['WILEY_BLEVINS'],
  ProductCodeEnum['SHORT_READS'],
  ProductCodeEnum['READ_TO_KNOW']
];

export const ModuleNameToModuleId: Record<string, string> = {
  'Wiley Blevins Teaching Phonics': 'R4R_WBTP',
  'Read To Know Text Sets': 'R4R_RTK',
  'Read To Know': 'R4R_RTK',
  'Short Reads Decodables': 'R4R_SRD'
};

export enum ModuleName {
  'R4R_WBTP' = 'Wiley Blevins Teaching Phonics',
  'R4R_SRD' = 'Short Reads Decodables',
  'R4R_RTK' = 'Read To Know'
}

enum QueryIdToModuleName {
  'r4r_module_001' = 'Wiley Blevins Teaching Phonics',
  'r4r_module_002' = 'Short Reads Decodables',
  'r4r_module_003' = 'Read To Know',
  'r4r_module_004' = 'Universal Assets'
}

export const TabsToDataTypeUrl: Record<string, string> = {
  Lessons: '?types=LESSON',
  Texts: '/ebooks',
  'Digital Activities': '?types=ACTIVITY',
  Videos: '?types=VIDEO',
  Resources: '/pdfs?pdfType=resource',
  Assessments: '/pdfs?pdfType=assessment'
};

export enum CategoryToTabName {
  LESSONS = 'Lessons',
  EBOOKS = 'Texts',
  ACTIVITIES = 'Digital Activities',
  VIDEO = 'Videos',
  RESOURCES = 'Resources',
  ASSESSMENTS = 'Assessments'
}

function Module() {
  const { state, dispatch } = useContext(StoreContext);

  const { id: moduleId } = useParams();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const initialTab = searchParams.get('tab');
  const initialPage = searchParams.get('page');
  const initialFilter = searchParams.get('filter');
  const { entitlements } = useAuth();
  const enabledModules = entitlements.includes(ProductCodeEnum['SYSTEM'])
    ? allModules
    : orderEntitlements(entitlements);
  const activeModule = getActiveModule(moduleId, enabledModules);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(initialPage ? Number(initialPage) : 1);
  const [currentFilter, setCurrentFilter] = useState(initialFilter ?? '');
  const [lessonIdFilter, setLessonIdFilter] = useState('');
  const firstLessonRef = useRef<HTMLLinkElement>(null);
  const [isAssignmentDialogOpen, setAssignmentDialogOpen] = useState<boolean>(false);
  const [assignmentImgUrl, setAssignmentImageUrl] = useState<string | undefined>('');
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentEReader, setAssignmentEreader] = useState<string>('');
  const [assignmentId, setAssignmentId] = useState<string>('');

  const testTab: Tab = {
    id: 0,
    label: ''
  };
  let tabs: Tab[] = [testTab];

  const { data } = useModule();
  // TODO: placeholder for loading state
  if (data) {
    for (const module of data) {
      if (module.moduleUuid === activeModule) {
        tabs = module['categories']?.map((category: string, index: number) => ({
          id: index + 1,
          label: category,
          urlModifier: TabsToDataTypeUrl[category]
        }));
      }
    }
  }

  const tabPages = {
    tabs: tabs
  };

  const setPageUrl = (selection: string, tab?: number, filter?: string, lessonIdFilter?: string) => {
    const moduleState = ModuleId[ModuleNameToModuleId[selection] as keyof typeof ModuleId];
    const tabSet = tab ?? 1;
    let url = `/module/${moduleState}?tab=${tabSet}&page=1`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    if (lessonIdFilter) {
      url += `&lessonId=${lessonIdFilter}`;
      setLessonIdFilter(lessonIdFilter);
    } else {
      setLessonIdFilter('');
    }
    setCurrentPage(1);
    setCurrentFilter(filter ?? '');
    dispatch({ type: StoreAction.SET_CURRENT_MODULE_TAB, data: tabSet });
    emitDirectCall('moduleType', { name: selection });
    navigate(url);
  };

  const handleTabChange = (tabNumber: number) => {
    setCurrentPage(1);
    setCurrentFilter('');
    setLessonIdFilter('');
    dispatch({ type: StoreAction.SET_CURRENT_MODULE_TAB, data: tabNumber });
  };

  const displayModuleTile = (currentValue: string) => {
    return <ModuleTile moduleId={ModuleId[ModuleNameToModuleId[currentValue] as keyof typeof ModuleId]} />;
  };

  /*PAGINATION LOGIC*/
  const getPaginatedData = (urlModifier: string, moduleId: string) => {
    let filter = '';
    if (moduleId === ModuleId.R4R_RTK.toString() && currentFilter) {
      urlModifier += urlModifier.includes('?') ? `&lessonId=${currentFilter}` : `?lessonId=${currentFilter}`;
    } else {
      filter = currentFilter;
    }
    return useQuery(
      ['getAssetsByModule', moduleId, urlModifier, currentPage, filter, lessonIdFilter],
      () => getAssetsByModule(moduleId, urlModifier, currentPage, filter, lessonIdFilter),
      { retry: false }
    );
  };

  const modifier = tabPages.tabs[Number(state.currentModuleTab) - 1]?.urlModifier;

  const { data: gridData, isLoading } = getPaginatedData(modifier ?? '/lessons', moduleId ?? activeModule);

  const gridItems = gridData?.results;
  const totalPages = gridData?.totalPages;

  function changePage(newPage: number) {
    setCurrentPage(newPage);
    let url = `?tab=${Number(state.currentModuleTab)}&page=${newPage}`;
    if (currentFilter) {
      url += `&filter=${currentFilter}`;
    }
    window.history.pushState(null, '', url);
    firstLessonRef.current?.focus();
  }

  function orderEntitlements(enitlements: ProductCode[]) {
    const entitlementOptions: ProductCode[] = ['R4R_WBTP', 'R4R_SRD', 'R4R_RTK'];
    return entitlementOptions.filter((option) => entitlements.includes(option));
  }

  function getActiveModule(selectedModule: string | undefined, allModules: string[]) {
    if (selectedModule) {
      return selectedModule;
    } else if (allModules.includes('R4R_WBTP')) {
      return ModuleId['R4R_WBTP'].toString();
    } else if (allModules.includes('R4R_SRD')) {
      return ModuleId['R4R_SRD'].toString();
    } else if (allModules.includes('R4R_RTK')) {
      return ModuleId['R4R_RTK'].toString();
    } else {
      return '';
    }
  }

  let moduleName = WBFullName;
  if (activeModule === ModuleId.R4R_SRD.toString()) {
    moduleName = SRDFullName;
  } else if (activeModule === ModuleId.R4R_RTK.toString()) {
    moduleName = RTKFullName;
  }

  const moduleOptions = enabledModules.map((moduleId, index) => ({
    id: String(index),
    content: ModuleName[moduleId as keyof typeof ModuleName],
    value: ModuleName[moduleId as keyof typeof ModuleName]
  }));

  const generateNavigationUrl = (item: any): string => {
    switch (item.type) {
      case AssetTypeName.activity.toString():
        return `${process.env.REACT_APP_ACTIVITY_PLAYER_URL}?id=${item.uuid}`;
      case AssetTypeName.ebook.toString():
        return `/${item.type.toLowerCase()}/${deflate(item.uuid)}`;
      case AssetTypeName.video.toString():
        return `/${item.type.toLowerCase()}/${deflate(item.uuid)}`;
      case AssetTypeName.lesson.toString():
        return `/${item.type.toLowerCase()}/${deflate(item.uuid)}`;
      case AssetTypeName.audio.toString():
        return `/${item.type.toLowerCase()}/${deflate(item.uuid)}`;
      case AssetTypeName.pdf.toString():
        return `${item.url}`;
      default:
        return '';
    }
  };

  const generateAssetType = (type: string): AssetType => {
    switch (type) {
      case CategoryToTabName.ASSESSMENTS:
        return 'assessment';
      case CategoryToTabName.EBOOKS:
        return 'ebook';
      case CategoryToTabName.LESSONS:
        return 'lesson';
      case CategoryToTabName.RESOURCES:
        return 'downloadable';
      case CategoryToTabName.VIDEO:
        return 'video';
      default:
        return 'activity';
    }
  };

  const onFilterDropdownSelection = (filterGroupId: string, filterGroupItemId?: string) => {
    setPageUrl(
      QueryIdToModuleName[activeModule as keyof typeof QueryIdToModuleName],
      Number(state.currentModuleTab),
      filterGroupId,
      filterGroupItemId
    );
  };

  function getCurrentType() {
    return tabs && tabs[Number(state.currentModuleTab) - 1]
      ? tabs[Number(state.currentModuleTab) - 1].label
      : CategoryToTabName.LESSONS;
  }

  const getFilterDropdownIfAvailable = () => {
    const currentType = getCurrentType();
    if (
      activeModule === ModuleId.R4R_WBTP.toString() &&
      (currentType === CategoryToTabName.LESSONS || currentType === CategoryToTabName.EBOOKS)
    ) {
      return (
        <FilterByDropdown
          moduleId={activeModule}
          initialFilterId={currentFilter}
          dataType={currentType}
          onFilterSelection={onFilterDropdownSelection}
          filterType={'Lesson Type'}
        />
      );
    } else if (
      (activeModule === ModuleId.R4R_WBTP.toString() &&
        (currentType === CategoryToTabName.ACTIVITIES ||
          currentType === CategoryToTabName.RESOURCES ||
          currentType === CategoryToTabName.ASSESSMENTS)) ||
      (activeModule === ModuleId.R4R_SRD.toString() && currentType === CategoryToTabName.RESOURCES)
    ) {
      return (
        <FilterByAccordionDropdown
          moduleId={activeModule}
          initialFilterId={currentFilter}
          dataType={currentType}
          onFilterSelection={onFilterDropdownSelection}
          filterType={'Lesson'}
        />
      );
    } else if (
      activeModule === ModuleId.R4R_SRD.toString() &&
      (currentType === CategoryToTabName.LESSONS || currentType === CategoryToTabName.EBOOKS)
    ) {
      return (
        <FilterByDropdown
          moduleId={activeModule}
          initialFilterId={currentFilter}
          dataType={currentType}
          onFilterSelection={onFilterDropdownSelection}
          filterType={'Teaching Guide'}
        />
      );
    } else if (
      activeModule === ModuleId.R4R_RTK.toString() &&
      (currentType === CategoryToTabName.EBOOKS ||
        currentType === CategoryToTabName.VIDEO ||
        currentType === CategoryToTabName.RESOURCES)
    ) {
      return (
        <FilterByDropdown
          moduleId={activeModule}
          initialFilterId={currentFilter}
          dataType={currentType}
          onFilterSelection={onFilterDropdownSelection}
          filterType={'Lesson'}
        />
      );
    } else {
      return null;
    }
  };

  const className = 'module-page';

  useEffect(() => {
    const currentModuleData = data?.find((i) => i.moduleUuid === activeModule);
    const tabInfo = !!currentModuleData
      ? currentModuleData.categories[Number(state.currentModuleTab) - 1]
      : undefined;
    let baseName;
    if (!!moduleName && !!tabInfo) {
      baseName = `Modules - ${moduleName} - ${tabInfo}`;
    }
    if (!!baseName) {
      updateDumbledataPage({ baseName, siteSection: 'Modules', previousPageURL: state?.previousPageURL });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
    }
  }, [moduleName, state.currentModuleTab, data]);

  useEffect(() => {
    dispatch({ type: StoreAction.SET_CURRENT_MODULE_TAB, data: initialTab ?? 1 });
  }, [initialTab]);

  const toggleAssignmentDialog = (
    assignmentImgUrl: string | undefined,
    assignmentName: string,
    assignmentEReader: string,
    assignmentId: string
  ) => {
    setAssignmentImageUrl(assignmentImgUrl);
    setAssignmentEreader(assignmentEReader);
    setAssignmentName(assignmentName);
    setAssignmentId(assignmentId);
    setAssignmentDialogOpen(!isAssignmentDialogOpen);
  };

  return (
    <PrimaryLayout>
      <div data-testid="module-page" className={className}>
        <h1 className="page-title">Browse Modules</h1>
        <div className={className + '__mobile-title'}>{moduleName}</div>
        {isAssignmentDialogOpen && (
          <AssignmentDialog
            imgUrl={assignmentImgUrl}
            eReaderUrl={assignmentEReader}
            assignmentName={assignmentName}
            onDialogChange={() => setAssignmentDialogOpen(!isAssignmentDialogOpen)}
            textId={assignmentId}
            activeModule={activeModule}
          />
        )}
        <CategoryTab
          {...tabPages}
          renderLeftMenu={() => {
            const currentValueIndex = moduleOptions.findIndex(({ value }) => value === moduleName);
            return (
              <div className="module-filter module-filter__module">
                <Select
                  options={moduleOptions}
                  initialSelected={currentValueIndex}
                  onSelect={setPageUrl}
                  displayCurrent={displayModuleTile(moduleOptions[currentValueIndex].value)}
                  id={'module-dropdown'}
                  screenReaderDescription={'module selection'}
                />
              </div>
            );
          }}
          currentTab={Number(state.currentModuleTab) - 1}
          setTabOnParent={handleTabChange}
        >
          <div className={`${className}__filter-dropdown`}>{getFilterDropdownIfAvailable()}</div>
          <>
            {gridData && (
              <PaginatedGrid
                changePage={changePage}
                currentPage={currentPage}
                totalPages={totalPages ? totalPages : 1}
              >
                <ResourcePanel>
                  <>
                    {gridItems?.map((gridItem, index) => {
                      const openNewTab =
                        tabs[Number(state.currentModuleTab) - 1].label !== CategoryToTabName.LESSONS;

                      const type = generateAssetType(tabs[Number(state.currentModuleTab) - 1].label);
                      let imgUrl: string | undefined;
                      if (tabs[Number(state.currentModuleTab) - 1].label === CategoryToTabName.VIDEO) {
                        imgUrl = gridItem.thumbnailUrl;
                      } else if (gridItem.thumbnailUrl) {
                        imgUrl = `${process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN}/${gridItem.thumbnailUrl}`;
                      }
                      const elementType = type === 'ebook' ? 'button' : 'link';
                      const navigationUrl = generateNavigationUrl(gridItem);
                      return (
                        <ResourcePanelItem
                          key={index}
                          type={type}
                          title={gridItem.title}
                          image={imgUrl && <img src={imgUrl} alt="" />}
                          innerRef={index === 0 ? firstLessonRef : null}
                          navigationUrl={navigationUrl}
                          openNewTab={openNewTab}
                          moduleUuid={activeModule}
                          tabName={tabs[Number(state.currentModuleTab) - 1].label}
                          elementType={elementType}
                          onButtonClick={
                            type === 'ebook'
                              ? () => {
                                  toggleAssignmentDialog(
                                    imgUrl,
                                    gridItem.title,
                                    navigationUrl,
                                    gridItem.uuid
                                  );
                                }
                              : undefined
                          }
                        />
                      );
                    })}
                  </>
                </ResourcePanel>
              </PaginatedGrid>
            )}
            {!isLoading && !gridData && <NoResultModulePage />}
          </>
        </CategoryTab>
        {/* <ComingSoonMessage featureName={'Browse by Module'} /> */}
      </div>
    </PrimaryLayout>
  );
}

export const NoResultModulePage = () => {
  const className = 'module-page';
  const noResultClassName = className + '__no_result';
  return (
    <div className={noResultClassName}>
      <p className={noResultClassName + '__header'}>No Results</p>
      <p className={noResultClassName + '__description'}>
        No results match your criteria. Please adjust your filters and try again.
      </p>
    </div>
  );
};
export default Module;
