import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import './Select.scss';

interface Option {
  id: string;
  value: any;
  content: string | React.ReactElement;
}

export interface SelectProps {
  id?: string;
  options: Option[];
  defaultText?: string;
  displayCurrent?: React.ReactElement;
  initialSelected?: number;
  onSelect?: (value: Option['value']) => void;
  dropdownStyles?: React.CSSProperties;
  dropdownOptionStyles?: React.CSSProperties;
  screenReaderDescription?: string;
  screenReaderOptionText?: string;
}

/**
 * A select dropdown control
 *
 * @component
 * @param id?: Optional id string
 * @param {Option[]} options: List of Options to display
 * @param defaultText?: An optional string to display in the Select if no option is chosen
 * @param {React.ReactElement} displayCurrent?: Optionally include a React component to show when a particular value is selected
 * @param initialSelected?: The index of the initially selected option
 * @param onSelect: Callback function to fire on option selection
 * @param dropdownStyles: Style object for the dropdown
 * @param dropdownOptionStyles: Style object for the dropdown buttons
 * @param screenReaderDescription: description for screen reader to give information on select
 * @param screenReaderOptionText: text to be read by the screen reader when dropdown option is seleced
 *
 * @example
 * <Select
 *   id="demo-select"
 *   options={[
 *    {id: '0', value: 'Option1', content: 'Option1'}
 *    {id: '1', value: 'Option2', content: 'Option2'}
 *   ]}
 *   defaultText="Select an option"
 *   displayCurrent={<div>Override the current selected content</div>}
 *   initialSelected={0}
 *   onSelect={handleSelect}
 * />
 *
 */

export default function Select({
  id,
  options,
  defaultText,
  displayCurrent,
  initialSelected,
  onSelect: handleSelect = () => {},
  dropdownStyles,
  dropdownOptionStyles,
  screenReaderDescription,
  screenReaderOptionText
}: SelectProps) {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(initialSelected ?? 0);
  const topOptionRef = useRef<HTMLDivElement>(null);
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialSelected !== selectedOptionIndex) {
      setSelectedOptionIndex(initialSelected ?? 0);
    }
  }, [initialSelected]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
    if (!isOptionsOpen) {
      sizeDropdown();
    }
  };

  React.useEffect(() => {
    topOptionRef.current?.focus();
    sizeDropdown();
    window.addEventListener('resize', () => sizeDropdown());
  }, []);

  const sizeDropdown = () => {
    if (!(dropdownStyles && dropdownStyles.width)) {
      const bound = dropdownTriggerRef.current?.getBoundingClientRect();
      if (bound && dropdownRef.current) {
        const width = bound.width + 'px';
        dropdownRef.current.style.width = width;
      } else {
        window.setTimeout(sizeDropdown, 10);
      }
    }
  };

  const setSelectedThenCloseDropdown = (index: number) => {
    setSelectedOptionIndex(index);
    handleSelect(options[index].value);
  };

  const className = 'select';
  const buttonClass = `${className}-button`;
  const expandedButtonClass = `${buttonClass}__expanded`;
  const optionsClass = `${className}-options`;
  const optionsContainerClass = `${optionsClass}-container`;

  const ButtonContent = () => {
    if (!initialSelected && !selectedOptionIndex && defaultText) {
      return <span>{defaultText}</span>;
    }
    return (
      displayCurrent || (
        <span>
          {options.length > 0 && options[selectedOptionIndex] && options[selectedOptionIndex].content}
        </span>
      )
    );
  };

  return (
    <DropdownMenu.Root onOpenChange={toggleOptions}>
      <DropdownMenu.Trigger className={`${className}-wrapper`} asChild>
        <button
          id={id}
          name={id}
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={classNames(buttonClass, { [expandedButtonClass]: isOptionsOpen })}
          data-testid={id}
          ref={dropdownTriggerRef}
          aria-label={`${screenReaderDescription ? screenReaderDescription : 'menu dropdown'} ${
            options.length > 0 && options[selectedOptionIndex] && options[selectedOptionIndex].content
          } selected`}
        >
          <ButtonContent />
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ transform: isOptionsOpen ? 'rotate(180deg)' : '' }}
          />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal className={optionsContainerClass}>
        <DropdownMenu.Content className={optionsClass} role="menu" ref={dropdownRef} style={dropdownStyles}>
          {options.map((option: Option, index) => (
            <DropdownMenu.Item
              key={index}
              id={option.id}
              role="option"
              aria-selected={selectedOptionIndex === index}
              aria-current={selectedOptionIndex === index}
              tabIndex={0}
              className={`${className}-option`}
              onSelect={() => setSelectedThenCloseDropdown(index)}
              data-testid={'page-' + option.id}
              ref={index === 0 ? topOptionRef : null}
              style={dropdownOptionStyles}
            >
              {option.content}
              <span className={'sr-only'}>{screenReaderOptionText}</span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
