import { PropsWithChildren } from 'react'
import { ElementTypes, Container as ContainerType } from '../types'

import './Container.scss'

export interface ContainerProps {
  element: ContainerType
}

const lessonClassName = 'lesson-content'
const baseClassName = `${lessonClassName}-${ElementTypes.CONTAINER}`

export default function Container({ children, element }: PropsWithChildren<ContainerProps>) {
  const { theme } = element
  const containerClassName = theme ? `${baseClassName} ${baseClassName}__theme-${theme}` : baseClassName

  return <div className={containerClassName}>{children}</div>
}
