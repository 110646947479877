import React from 'react';
import './NavLink.scss';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Markup } from 'interweave';

interface NavLinkProps {
  label: string;
  path: string;
  onClick?: () => void | undefined;
}

/**
 * Create a navigation link designed for the GlobalSubHeader
 *
 * @param {string} label - A label for the link
 * @param {string} path - A path for the link
 *
 * @example
 * <NavLink label="some label" path="/skills"/>
 *
 */
function NavLink({ label, path, onClick }: NavLinkProps) {
  const className = 'sub-header__nav';
  return (
    <div className={className}>
      <RouterNavLink
        onClick={onClick ? () => onClick() : () => {}}
        data-testid="nav-link"
        to={path}
        className={({ isActive }) => (isActive ? `${className}-link active` : `${className}-link`)}
      >
        <Markup content={label} />
      </RouterNavLink>
    </div>
  );
}

export default NavLink;
