import { ModuleId } from 'api-library';
import { BaseModuleTile } from './BaseModuleTile/BaseModuleTile';
import { PropsWithChildren } from 'react';

interface ModuleProps {
  moduleId: ModuleId;
}

const RTKModuleTile = ({ children }: PropsWithChildren) => {
  return (
    <BaseModuleTile
      titles={
        <>
          <BaseModuleTile.Title gradient={['#55B700', '#2F8900']}>Read to Know</BaseModuleTile.Title>
          <BaseModuleTile.SubTitle>Text Sets</BaseModuleTile.SubTitle>
        </>
      }
      fullTitle={'Read to Know Text Sets'}
    >
      {children}
    </BaseModuleTile>
  );
};

const SRDModuleTile = ({ children }: PropsWithChildren) => {
  return (
    <BaseModuleTile
      titles={
        <>
          <BaseModuleTile.SubTitle>Short Reads</BaseModuleTile.SubTitle>
          <BaseModuleTile.Title gradient={['#00AAD8', '#005099']}>Decodables</BaseModuleTile.Title>
        </>
      }
      fullTitle={'Short Reads Decodables'}
    >
      {children}
    </BaseModuleTile>
  );
};

const WBTPModuleTile = ({ children }: PropsWithChildren) => {
  return (
    <BaseModuleTile
      titles={
        <>
          <BaseModuleTile.SubTitle>Wiley Blevins</BaseModuleTile.SubTitle>
          <BaseModuleTile.Title gradient={['#ff8500', '#ff4200']}>Teaching Phonics</BaseModuleTile.Title>
        </>
      }
      fullTitle={'Wiley Blevins Teaching Phonics'}
    >
      {children}
    </BaseModuleTile>
  );
};

const UniversalAssetModuleTile = ({ children }: PropsWithChildren) => {
  return (
    <BaseModuleTile titles={<BaseModuleTile.TitleImage />} fullTitle={'Universal Assets'}>
      {children}
    </BaseModuleTile>
  );
};

export function ModuleTile({ moduleId, ...props }: PropsWithChildren<ModuleProps>) {
  if (moduleId === ModuleId.R4R_RTK) {
    return <RTKModuleTile {...props} />;
  }
  if (moduleId === ModuleId.R4R_SRD) {
    return <SRDModuleTile {...props} />;
  }
  if (moduleId === ModuleId.R4R_WBTP) {
    return <WBTPModuleTile {...props} />;
  }
  if (moduleId === ModuleId.R4R_UA) {
    return <UniversalAssetModuleTile {...props} />;
  }
  return null;
}
