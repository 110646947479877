import { Markup } from 'interweave';
import type { MaterialCard } from '../types';
import { getImageSource } from '../utilities';
import './MaterialCard.scss';

const className = 'material-card';

interface MaterialCardProps {
  element: MaterialCard;
}

export default function MaterialCard({ element }: MaterialCardProps) {
  const imageSource = getImageSource(element);

  return (
    <div className={className}>
      <img className={`${className}-image`} src={imageSource} alt="" />
      <Markup className={`${className}-content`} tagName="div" content={element.html} />
    </div>
  );
}
