import './WelcomeBanner.scss';
import { Link } from 'react-router-dom';
import { routeTypeIndexes, routeTypes } from '../../pages/pageData';
import { emitDirectCall } from '../../utilities/DumbleData';

export function WelcomeBanner() {
  const className = 'welcome-banner';
  return (
    <div className={className}>
      <div className={`${className}__heading-container`}>
        <h1 className={`${className}__heading`}>Welcome to Ready4Reading!</h1>
      </div>
      <div className={`${className}__subheading-container`}>
        <p className={`${className}__subheading`}>Get started by finding a lesson. </p>
        <p className={`${className}__subheading`}>
          Browse by{' '}
          <Link
            to={routeTypes[routeTypeIndexes.MODULE].path}
            onClick={() => emitDirectCall('welcomeBanner', { name: 'Module' })}
          >
            module
          </Link>{' '}
          or by{' '}
          <Link
            to={routeTypes[routeTypeIndexes.SKILLS].path}
            onClick={() => emitDirectCall('welcomeBanner', { name: 'Skill' })}
          >
            skill
          </Link>
          .
        </p>
      </div>
    </div>
  );
}
