import React, { useEffect } from 'react'

import uniqid from 'uniqid'
import classNames from 'classnames'

import './AblePlayer.scss'

export interface AbleAudioPlayerProps {
  playerId: string
  audioSrcOgg?: string
  audioSrcMpeg: string
  playerTheme?: 'legacy' | '2020'
  preLoad?: boolean
  speedIcon?: 'animals' | 'arrows'
  playerClass?: string
}

export const AblePlayerAudio = ({
  playerId = uniqid(),
  audioSrcOgg,
  audioSrcMpeg,
  playerTheme = '2020',
  preLoad,
  speedIcon = 'arrows',
  playerClass
}: AbleAudioPlayerProps) => {
  useEffect(() => {
    const loadScript = function (src: string, id: string) {
      if (document.getElementById(id)) return
      const tag = document.createElement('script')
      tag.async = false
      tag.src = src
      tag.id = id
      document.body.appendChild(tag)
    }

    const loadStyle = function (src: string, id: string) {
      if (document.getElementById(id)) return
      const tag = document.createElement('link')
      tag.rel = 'stylesheet'
      tag.href = src
      tag.id = id
      document.body.appendChild(tag)
    }

    loadScript('//ajax.googleapis.com/ajax/libs/jquery/3.5.0/jquery.min.js', 'jquery')
    loadScript('//cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js', 'js-cookie')
    loadScript(`${process.env.PUBLIC_URL}/ableplayer/build/ableplayer.js`, 'ableplayer-js')
    loadStyle(`${process.env.PUBLIC_URL}/ableplayer/build/ableplayer.min.css`, 'ableplayer-css')
  }, [])

  return (
    <div className="ableplayer-wrapper">
      <audio
        id={playerId}
        className={classNames('ableplayer-wrapper__item', playerClass)}
        data-able-player
        preload={preLoad ? 'auto' : 'metadata'}
        data-skin={playerTheme}
        data-speed-icons={speedIcon}
      >
        {audioSrcOgg && <source type="audio/ogg" src={audioSrcOgg} />}
        {audioSrcMpeg && <source type="audio/mpeg" src={audioSrcMpeg} />}
      </audio>
    </div>
  )
}
