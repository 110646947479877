import React, { useContext } from 'react';
import './GlobalSubHeader.scss';
import { routeTypeIndexes, routeTypes } from '../../pages/pageData';
import NavLink from '../NavLink/NavLink';
import SubHeader from '../SubHeader/SubHeader';
import { StoreAction, StoreContext } from '../../store/store';
import { emitDirectCall } from '../../utilities/DumbleData';

/**
 * Create the global sub header
 *
 * @example
 * <GlobalSubHeader/>
 *
 */
const GlobalSubHeader = () => {
  const { dispatch } = useContext(StoreContext);
  const onModuleClick = () => {
    dispatch({ type: StoreAction.SET_CURRENT_MODULE_TAB, data: 1 });
  };

  const className = 'global-sub-header';
  return (
    <SubHeader
      children={
        <div className={`${className}__nav-container`}>
          <NavLink
            label="Home"
            path={routeTypes[routeTypeIndexes.HOME].path}
            onClick={() => emitDirectCall('nav', { name: 'Home' })}
          />
          <NavLink
            label="Skills"
            path={routeTypes[routeTypeIndexes.SKILLS].path}
            onClick={() => emitDirectCall('nav', { name: 'Skills' })}
          />
          <NavLink
            onClick={() => {
              onModuleClick();
              emitDirectCall('nav', { name: 'Modules' });
            }}
            label="Modules"
            path={routeTypes[routeTypeIndexes.MODULE].path}
          />
          <NavLink
            label="System Resources"
            path={routeTypes[routeTypeIndexes.SYSTEM_RESOURCES].path}
            onClick={() => emitDirectCall('nav', { name: 'System Resources' })}
          />
        </div>
      }
    />
  );
};

export default GlobalSubHeader;
