import React, { PropsWithChildren, useEffect, useState } from 'react';
import './CategoryTab.scss';
import { emitDirectCall } from '../../utilities/DumbleData';

export type Tab = {
  id: number;
  label: string;
  component?: React.ReactElement;
  urlModifier?: string;
};

export type TabContentData = {
  moduleId: string;
  dataType: string;
};

export type CategoryTabProps = {
  tabs: Tab[];
  renderLeftMenu?: () => React.ReactElement | undefined;
  renderRightMenu?: () => React.ReactElement | undefined;
  setTabOnParent?: (id: number) => void;
  currentTab?: number;
};

export const CategoryTab = (props: PropsWithChildren<CategoryTabProps>) => {
  const { renderLeftMenu = () => <div />, renderRightMenu = () => <div /> } = props;
  const initialTab = props.currentTab ? props.currentTab : 0;
  const [activeTab, setActiveTab] = useState<Tab | null>(
    props.tabs.length > initialTab ? props.tabs[initialTab] : null
  );

  useEffect(() => {
    setActiveTab(props.tabs.length > initialTab ? props.tabs[initialTab] : null);
  }, [props.tabs]);

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
    if (props.setTabOnParent) {
      props.setTabOnParent(tab.id);
    }
    const analyticsRule = ['Guides', 'Additional Lessons', 'Additional Materials'].includes(tab.label)
      ? 'resourceType'
      : 'moduleResource';
    emitDirectCall(analyticsRule, { name: tab.label });
    window.history.pushState(null, '', `?tab=${tab.id}&page=1`);
  };

  return (
    <div className="category-tab">
      <div className="category-tab-header">
        {renderLeftMenu()}
        <div className="tab-buttons" data-testid="category-tab">
          {props.tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => {
                handleTabClick(tab);
              }}
              className={`tab-button ${activeTab?.id === tab.id ? 'active' : ''}`}
            >
              <p className="tab-button-text">{tab.label}</p>
            </button>
          ))}
        </div>
        {renderRightMenu()}
      </div>

      <div className="tab-content">
        {activeTab?.component}
        {props.children}
      </div>
    </div>
  );
};

export default CategoryTab;
