import { useQuery } from 'react-query'
import { useAuth } from 'authentication-library'
import { getCategoriesbyModule } from 'api-library'

export default function useModule() {
  const { modules } = useAuth()
  return useQuery(['getCategoriesbyModule', modules.toString()], () => getCategoriesbyModule(modules), {
    enabled: !!modules
  })
}
