import { AssetMenu } from '../../../components/AssetMenu/AssetMenu';
import type { LessonAssetData, RelatedMaterialData } from 'api-library';
import { AssetType, getModules, Module } from 'api-library';
import './LessonSidebarMenu.scss';
import { useQuery } from 'react-query';

interface LessonSidebarMenuProps {
  materials?: RelatedMaterialData;
  moduleUuid?: string;
}

interface RelatedMaterialsProps {
  materials?: RelatedMaterialData;
  moduleUuid?: string;
}

interface ConnectionsProps {
  lessons?: LessonAssetData[];
}

const className = 'lesson-sidebar-menu';

export function LessonSidebarMenu({ materials = {}, moduleUuid }: LessonSidebarMenuProps) {
  const { LESSON, ...restMaterials } = materials;
  const hasLessons = LESSON && LESSON.length > 0;
  const hasMaterials = restMaterials && Object.keys(restMaterials).length > 0;

  if (!hasLessons && !hasMaterials) {
    return null;
  }

  return (
    <div className={className}>
      {hasMaterials && <RelatedMaterials materials={restMaterials} moduleUuid={moduleUuid} />}
      {hasLessons && <Connections lessons={LESSON} />}
    </div>
  );
}

export function RelatedMaterials({ materials = {}, moduleUuid }: RelatedMaterialsProps) {
  const { ACTIVITY, PDF, VIDEO, EBOOK, AUDIO } = materials;

  if (!ACTIVITY && !PDF && !VIDEO && !EBOOK && !AUDIO) {
    return null;
  }

  return (
    <AssetMenu title={'Lesson Materials'}>
      {ACTIVITY && (
        <AssetMenu.Section title={'Digital Activities'} assets={ACTIVITY} assetType={AssetType.activity} />
      )}
      {PDF && <AssetMenu.Section title={'Resources'} assets={PDF} assetType={AssetType.pdf} />}
      {VIDEO && <AssetMenu.Section title={'Videos'} assets={VIDEO} assetType={AssetType.video} />}
      {AUDIO && <AssetMenu.Section title={'Audio'} assets={AUDIO} assetType={AssetType.audio} />}
      {EBOOK && (
        <AssetMenu.Section
          title={'Texts'}
          assets={EBOOK}
          assetType={AssetType.ebook}
          moduleUuid={moduleUuid}
        />
      )}
    </AssetMenu>
  );
}

export function Connections({ lessons }: ConnectionsProps) {
  if (!lessons || lessons.length === 0) {
    return null;
  }
  const { data: modulesData } = useQuery(['getModules'], () => getModules());
  const moduleList = modulesData ?? ([] as Module[]);
  return (
    <AssetMenu title={'Ready4Reading Connections'} themeClassName={'r4r-connections'}>
      {lessons.map((module) => {
        const currentModule = moduleList.find((moduleData) => moduleData.id === module.moduleUuid);
        const moduleTitle = currentModule
          ? currentModule.title === 'Universal Assets'
            ? 'Additional Lessons'
            : currentModule.title
          : '';
        return (
          <AssetMenu.Section
            title={moduleTitle}
            assets={module.lessons}
            assetType={AssetType.lesson}
            moduleUuid={module.moduleUuid}
            key={module.moduleUuid}
          />
        );
      })}
    </AssetMenu>
  );
}
