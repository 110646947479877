import SidebarLayout from '../layouts/SidebarLayout/SidebarLayout';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Markup } from 'interweave';

import { getLesson, getLessonTab, getRelatedMaterials } from 'api-library';

import { MemoizedLessonContent } from './ParsedLessonContent/ParsedLessonContent';
import { LessonSidebarMenu } from './LessonSidebarMenu/LessonSidebarMenu';
import { inflate } from '../../utilities/uuid';
import { useAuth } from 'authentication-library';

import './Lesson.scss';
import { useContext, useEffect } from 'react';
import updateDumbledataPage from '../../utilities/DumbleData';
import { StoreAction, StoreContext } from '../../store/store';
import { getModuleIds } from '../../utilities/ModuleUtil';

const Lesson = () => {
  const { modules } = useAuth();
  const { id, sequence } = useParams();
  const uuid = inflate('r4r_lesson_', id) as string;

  const { state, dispatch } = useContext(StoreContext);

  const tabNumber = sequence && Number(sequence) ? parseInt(sequence, 10) : 1;

  const { data: lessonTabData } = useQuery(['getLessonTabData', uuid, tabNumber], () =>
    getLessonTab(uuid, tabNumber)
  );

  const { data: lessonData } = useQuery(['getLessonData', uuid], () => getLesson(uuid));

  const { data: relatedMaterialsData } = useQuery(['getRelatedMaterials', uuid], () =>
    getRelatedMaterials(uuid, getModuleIds(modules))
  );

  const { title, moduleUuid, tabs: tabNames } = lessonData ?? {};

  const className = 'lesson';

  const tabName = tabNames?.[tabNumber - 1];
  useEffect(() => {
    let baseName;
    if (moduleUuid === 'r4r_module_004' && !!tabName) {
      baseName = 'Lessons - ' + tabName;
    } else if (!!title) {
      baseName = 'Lessons - ' + title;
    }
    if (!!baseName) {
      updateDumbledataPage({ baseName, siteSection: 'Lessons', previousPageURL: state?.previousPageURL });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
    }
  }, [lessonData, lessonTabData]);
  return (
    <div
      data-testid={'lesson'}
      className={className + (moduleUuid ? ` ${className}-module--` + moduleUuid : '')}
    >
      <SidebarLayout
        sidebar={
          <>
            <LessonSidebarMenu materials={relatedMaterialsData} moduleUuid={moduleUuid} />
          </>
        }
        reverseLayout={true}
      >
        <h1 className={`${className}__name`}>
          <Markup content={title} tagName="fragment" />
          {tabName && (
            <>
              <br />
              <Markup content={tabName} className={`${className}__tab`} />
            </>
          )}
        </h1>
        {lessonTabData && <MemoizedLessonContent content={lessonTabData.content} />}
      </SidebarLayout>
    </div>
  );
};

export default Lesson;
