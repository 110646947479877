import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PrimaryLayout from '../layouts/PrimaryLayout/PrimaryLayout';
import { WelcomeBanner } from '../../components/WelcomeBanner/WelcomeBanner';
import SkillNavigationPanel from '../../components/SkillNavigationPanel/SkillNavigationPanel';
import { AssetOverview } from '../../components/AssetOverview/AssetOverview';
import { ModuleNavigation } from '../../components/ModuleNavigation/ModuleNavigation';
import { routeTypeIndexes, routeTypes } from '../pageData';
import { useAuth } from 'authentication-library';

import useSkillCategories from '../../queries/useSkillCategories';
import { GlobalFooter, GlobalHeader } from 'component-library';
import useSubHeader from '../../utilities/useSubHeader';
import GlobalSubHeader from '../../components/GlobalSubHeader/GlobalSubHeader';
import GuideImage from '../../assets/images/implementation_guide.png';
import { ReactComponent as BottomSwoosh } from '../../assets/images/bottom_Swoosh.svg';
import { ReactComponent as TopSwoosh } from '../../assets/images/top_Swoosh.svg';
import './Home.scss';
import updateDumbledataPage, { emitDirectCall } from '../../utilities/DumbleData';
import { StoreAction, StoreContext } from '../../store/store';

const Home = () => {
  const { entitlements } = useAuth();
  const { state, dispatch } = useContext(StoreContext);

  const { isSuccess, data: skillData } = useSkillCategories();
  const SubHeader = useSubHeader();
  const className = 'home-page';
  useEffect(() => {
    updateDumbledataPage({
      baseName: 'Teacher Hub Home',
      siteSection: 'Teacher Hub Home',
      previousPageURL: state?.previousPageURL
    });
    dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
  }, []);

  return (
    <div className={className}>
      <PrimaryLayout>
        <>
          <GlobalHeader>
            <>
              <TopSwoosh className={'top-swoosh'} />
              <SubHeader />
            </>
          </GlobalHeader>
          <WelcomeBanner />
          <GlobalSubHeader />
          <main id="main-content" className={className + '__content'}>
            {isSuccess && <SkillNavigationPanel skillData={skillData} />}
            <ModuleNavigation modules={entitlements} />
            <AssetOverview
              title="How to use Ready4Reading"
              imageAlt="Lorem ipsum"
              imageSrc={GuideImage}
              callToAction={
                <Link
                  to={routeTypes[routeTypeIndexes.SYSTEM_RESOURCES].path}
                  style={{ color: '#004eb9' }}
                  onClick={() => emitDirectCall('implementationGuide', { name: 'Explore the Resources' })}
                >
                  Explore the Resources &gt;
                </Link>
              }
            >
              The System Resources tab is an access point for Ready4Reading Guides, including the
              Implementation Guide, User Guides, and the Planning and Pacing Guide. Module Guides are also
              available. Additional Materials offered include Word Study Skills Lessons, Phonics Skills
              Lessons, and Spelling Lists.
              <BottomSwoosh className={'bottom-swoosh'} />
            </AssetOverview>
          </main>
          <GlobalFooter />
        </>
      </PrimaryLayout>
    </div>
  );
};

export default Home;
