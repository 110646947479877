import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import GlobalSubHeader from '../components/GlobalSubHeader/GlobalSubHeader'
import App from '../App'

import Home from './Home/Home'
import Skills from './Skills/Skills'
import Module from './Module/Module'
import SystemResources from './SystemResources/SystemResources'
import Lesson from './Lesson/Lesson'
import { LessonSubHeader } from './Lesson/LessonSubHeader/LessonSubHeader'
import EReader from './EReader/EReader'
import Video from './Video/Video'
import Audio from './Audio/Audio'

import { pageRoutes } from './pageData'

export const routes = createRoutesFromElements(
  <>
    <Route index element={<Home />} />
    <Route path="/" element={<App />}>
      <Route handle={{ header: GlobalSubHeader }}>
        <Route path={pageRoutes.SKILLS} element={<Skills />}>
          <Route path={`${pageRoutes.SKILLS}/:categoryId/:skillId`} element={<Skills />} />
          <Route path={`${pageRoutes.SKILLS}/:categoryId`} element={<Skills />} />
        </Route>
        <Route path={pageRoutes.MODULE} element={<Module />}>
          <Route path={`${pageRoutes.MODULE}/:id`} element={<Module />} />
        </Route>
        <Route path={pageRoutes.SYSTEM_RESOURCES} element={<SystemResources />} />
      </Route>
      <Route handle={{ header: LessonSubHeader }}>
        <Route path={pageRoutes.LESSON} element={<Lesson />} />
        <Route path={pageRoutes.LESSON_TAB} element={<Lesson />} />
      </Route>
    </Route>
    <Route path={pageRoutes.EREADER} element={<EReader />} />
    <Route path={pageRoutes.EREADER_BOOK} element={<EReader />} />
    <Route path={pageRoutes.VIDEO} element={<Video />} />
    <Route path={pageRoutes.VIDEO_PLAYER} element={<Video />} />
    <Route path={pageRoutes.AUDIO} element={<Audio />} />
    <Route path={pageRoutes.AUDIO_PLAYER} element={<Audio />} />
  </>
)
const router = createBrowserRouter(routes)

export default router
