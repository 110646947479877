import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import { AblePlayerAudio } from '../../components/AblePlayer/AblePlayerAudio';
import AssetThumbnail from '../../components/AssetThumbnail/AssetThumbnail';

import { useAsset } from '../../queries/useAsset';
import updateDumbledataPage from '../../utilities/DumbleData';

import { inflate } from '../../utilities/uuid';
import { StoreAction, StoreContext } from '../../store/store';

export default function Audio() {
  const { id } = useParams();
  const uuid = inflate('r4r_aud_', id) as string;

  const { data, isLoading } = useAsset(uuid);
  const { state, dispatch } = useContext(StoreContext);

  const audioMpegSrc = data?.url;

  useEffect(() => {
    if (!!data) {
      const localURL = localStorage.getItem('previousPageURL') || undefined;
      updateDumbledataPage({
        data,
        baseName: 'Audio',
        siteSection: 'Audio Player',
        previousPageURL: state?.previousPageURL || localURL
      });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
      if (localURL) {
        localStorage.removeItem('previousPageURL');
      }
    }
  }, [data]);

  // TODO: placeholder for loading state
  if (isLoading) {
    return null;
  }

  if (!audioMpegSrc) {
    return <AssetThumbnail type="audio" />;
  }

  return (
    <AblePlayerAudio
      playerId={'teacher-hub-audio_' + uniqid()}
      audioSrcMpeg={audioMpegSrc}
      playerTheme="legacy"
    />
  );
}
