import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './ModuleNavigation.scss';
import { ModuleTile } from '../ModuleTile/ModuleTile';
import { ProductCodeEnum, ProductCode } from '../../types/authentication.types';
import { ModuleId } from 'api-library';
import { emitDirectCall } from '../../utilities/DumbleData';
import { ModuleName } from '../../pages/Module/Module';

interface ModuleNavigationProps {
  modules: ProductCode[];
}

interface ModuleNavigationLinkProps extends LinkProps {
  disabled?: boolean;
}

/**
 * Component for asset overview information
 *
 * @component
 * @param ModuleNavigation Asset overview information
 *
 * @example
 *  <ModuleNavigation />
 *
 * @return a navigation component that links to the modules
 */

const allModules = [
  ProductCodeEnum['WILEY_BLEVINS'],
  ProductCodeEnum['SHORT_READS'],
  ProductCodeEnum['READ_TO_KNOW']
];

function ModuleNavigationLink(props: PropsWithChildren<ModuleNavigationLinkProps>) {
  const { children, disabled, ...linkProps } = props;
  if (disabled) {
    return (
      <a role="link" aria-disabled="true" className="module-link__disabled">
        {children}
      </a>
    );
  }
  return <Link {...linkProps}>{children}</Link>;
}

export function ModuleNavigation({ modules }: ModuleNavigationProps) {
  const className = 'module-navigation';
  const enabledModules = modules.includes(ProductCodeEnum['SYSTEM']) ? allModules : modules;
  return (
    <div className={className}>
      <h2 className={`${className}__heading`}>Browse by module</h2>
      <nav className={`${className}__grid`}>
        <ModuleNavigationLink
          to="/module/r4r_module_001"
          onClick={() => emitDirectCall('browseModule', { name: ModuleName.R4R_WBTP })}
          disabled={!enabledModules.includes(ProductCodeEnum['WILEY_BLEVINS'])}
        >
          <ModuleTile moduleId={ModuleId.R4R_WBTP}>
            <span>
              <strong>Teach</strong> foundational phonics skills
            </span>
          </ModuleTile>
        </ModuleNavigationLink>
        <ModuleNavigationLink
          to="/module/r4r_module_002"
          onClick={() => emitDirectCall('browseModule', { name: ModuleName.R4R_SRD })}
          disabled={!enabledModules.includes(ProductCodeEnum['SHORT_READS'])}
        >
          <ModuleTile moduleId={ModuleId.R4R_SRD}>
            <span>
              <strong>Practice</strong> decoding skills with short texts
            </span>
          </ModuleTile>
        </ModuleNavigationLink>
        <ModuleNavigationLink
          to="/module/r4r_module_003"
          onClick={() => emitDirectCall('browseModule', { name: ModuleName.R4R_RTK })}
          disabled={!enabledModules.includes(ProductCodeEnum['READ_TO_KNOW'])}
        >
          <ModuleTile moduleId={ModuleId.R4R_RTK}>
            <span>
              <strong>Apply</strong> phonics skills and build knowledge
            </span>
          </ModuleTile>
        </ModuleNavigationLink>
      </nav>
    </div>
  );
}

function selectModule(moduleId: string) {}
