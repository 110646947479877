import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Markup } from 'interweave';
import { AccordionMenu } from '../../components/AccordionMenu/AccordionMenu';
import { deflate } from '../../utilities/uuid';
import type { SkillCategory } from 'api-library';
import './SidebarMenu.scss';
import { emitDirectCall } from '../../utilities/DumbleData';

const className = 'skills-sidebar-menu';

interface SidebarMenuProps {
  skillCategories?: Array<Partial<Pick<SkillCategory, 'uuid' | 'name' | 'skills'>>>;
  initialActiveCategory?: string;
  initialActiveSkill?: string;
}

export function SidebarMenu({
  skillCategories,
  initialActiveCategory,
  initialActiveSkill
}: SidebarMenuProps) {
  const navigate = useNavigate();

  if (!skillCategories) {
    return null;
  }

  const createSubmenuItems = (categoryId: string, categoryTitle: string, skills: SkillCategory['skills']) => {
    const allSkillsItem = {
      id: `all-${categoryId}-skills`,
      content: `All ${categoryTitle} Skills`,
      onClick: () => navigate(`/skills/${deflate(categoryId)}`)
    };

    const subMenuItems = skills.map(({ uuid: skillId, name: skillTitle }) => ({
      id: skillId,
      content: <Markup content={skillTitle} tagName="fragment" />,
      onClick: () => navigate(`/skills/${deflate(categoryId)}/${deflate(skillId)}`)
    }));
    return [allSkillsItem, ...subMenuItems];
  };

  const menuItems = skillCategories.map(({ uuid: categoryId, name: categoryTitle, skills }) => ({
    id: categoryId,
    content: categoryTitle,
    onClick: () => {
      emitDirectCall('skillCategory', { name: categoryTitle });
      navigate(`/skills/${deflate(categoryId)}`);
    },
    // generate submenu items if the category has skills and is not the Alphabet category
    menuItems:
      skills && categoryTitle !== 'Alphabet'
        ? createSubmenuItems(categoryId as string, categoryTitle as string, skills)
        : undefined
  }));

  const initialActive = [initialActiveCategory, initialActiveSkill || `all-${initialActiveCategory}-skills`];

  return (
    <div className={className}>
      <AccordionMenu menuItems={menuItems} initialActive={initialActive} />
    </div>
  );
}
