import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import styles from './Checkbox.module.scss';
import * as CheckboxRadix from '@radix-ui/react-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CheckBoxProps {
  labelContent: string;
  onCheckChange: () => void;
  checkedState: boolean;
  checkBoxId: string;
  ariaLabel: string;
  dataTestId: string;
  checkedIcon: IconDefinition;
}

const Checkbox = ({
  labelContent,
  onCheckChange,
  checkedState,
  checkBoxId,
  ariaLabel,
  dataTestId,
  checkedIcon
}: CheckBoxProps) => {
  return (
    <div className={styles.checkbox}>
      <CheckboxRadix.Root
        onCheckedChange={onCheckChange}
        className={styles.checkboxRoot}
        checked={checkedState}
        id={checkBoxId}
        data-testid={dataTestId}
        aria-labelledby={ariaLabel}
      >
        <CheckboxRadix.Indicator className={styles.CheckboxIndicator}>
          <FontAwesomeIcon className={styles.checkIcon} inverse icon={checkedIcon} aria-hidden />
        </CheckboxRadix.Indicator>
      </CheckboxRadix.Root>
      <label className={styles.label} id={ariaLabel} htmlFor={checkBoxId}>
        {labelContent}
      </label>
    </div>
  );
};

export default Checkbox;
