import React from 'react';
import { ReactComponent as GlobeIcon } from '../../../assets/images/globe.svg';
import './Pill.scss';
import { Markup } from 'interweave';

/**
 * Renders a pill for emphasized text
 *
 * @component
 * @param html: sets html content within the pill
 *
 * @example
 * <Pill
 *         html={'<b>Available in English and Spanish</b>'}
 *       />
 *
 */

interface PillProps {
  html: string;
}

const Pill = (props: PillProps) => {
  const className = 'pill';
  return (
    <div className={className}>
      <GlobeIcon aria-hidden />
      <Markup className={className + '__text'} content={props.html}></Markup>
    </div>
  );
};

export default Pill;
