import React, { useState, useEffect } from 'react';
import * as Dialogue from '@radix-ui/react-dialog';
import XCloseIcon from '../../assets/images/nav-close.svg';
import styles from './Dialog.module.scss';

export interface DialogProps {
  content?: React.ReactNode;
  openDialogButton?: React.ReactNode;
  isOpen?: boolean;
  onDialogChange?: (isChanged: boolean) => void;
  dialogStyles?: React.CSSProperties;
}

const Dialog = ({ content, openDialogButton, isOpen, onDialogChange, dialogStyles }: DialogProps) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialogue.Root open={open} onOpenChange={onDialogChange ?? setOpen} modal>
      {openDialogButton ? <Dialogue.Trigger>{openDialogButton}</Dialogue.Trigger> : null}
      <Dialogue.Portal>
        <Dialogue.Overlay className={styles.dialogOverlay} />
        <Dialogue.Content
          className={styles.dialogContent}
          forceMount
          onOpenAutoFocus={(e: Event) => {
            e.preventDefault();
            const header = document.getElementById('modalTitle');
            header?.focus();
          }}
          style={dialogStyles}
          aria-describedby={undefined}
        >
          {content}
          <Dialogue.Close asChild>
            <button className={styles.closeIcon} aria-label="Close" autoFocus>
              <img src={XCloseIcon} alt={'Close'} aria-hidden />
            </button>
          </Dialogue.Close>
        </Dialogue.Content>
      </Dialogue.Portal>
    </Dialogue.Root>
  );
};

export default Dialog;
