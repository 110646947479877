import React from 'react'
import './AssetOverview.scss'

interface AssetOverviewProps {
  callToAction?: React.ReactNode
  children?: React.ReactNode
  imageSrc: string
  imageAlt: string
  title?: string
}

/**
 * Component for asset overview information
 *
 * @component
 * @param AssetOverview Asset overview information
 * @param AssetOverview.callToAction A slot to place a component that initiates a call to action, e.g. a button or link
 * @param AssetOverview.children Will display summary text for the section
 * @param AssetOverview.imageSrc Specifies the source of the thumbnail image
 * @param AssetOverview.imageAlt Speficies the alternate text for the thumbnail image
 * @param AssetOverview.title Title text for the section
 *
 * @example
 *  <AssetOverview
 *    title="How to use Ready4Reading"
 *    imageAlt="Lorem ipsum"
 *    imageSrc="https://placeimg.com/200/260/tech"
 *    callToAction={<a href="#">Click me</a>}
 *  >
 *    Lorem ipsum dolor sit amet
 *  </AssetOverview>
 *
 * @return an asset overview component with a title, summary and thumbnail image
 */

export function AssetOverview({ imageAlt, imageSrc, title, children, callToAction }: AssetOverviewProps) {
  const className = 'asset-overview'
  return (
    <div className={className}>
      <div className={`${className}__image`}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
      <div className={`${className}__content`}>
        <h2 className={`${className}__title`}>{title}</h2>
        <div className={`${className}__summary`} data-testid="summary">
          {children}
        </div>
        <div className={`${className}__call-to-action`}>{callToAction}</div>
      </div>
    </div>
  )
}
