import React from 'react';
import './ExternalNavLink.scss';
import { emitDirectCall } from '../../utilities/DumbleData';

interface NavExternalLinkProps {
  path: string;
  label: string;
}

/**
 * Create an external navigation link designed for the GlobalSubHeader
 *
 * @param {string} label - A label for the link
 * @param {string} path - A path for the link
 *
 * @example
 * <NavLink label="some label" path="/skills"/>
 *
 */
const ExternalNavLink = ({ path, label }: NavExternalLinkProps) => {
  const className = 'global-sub-header__external-nav';
  const handleClick = () => {
    emitDirectCall('subnav', { name: label });
  };
  return (
    <div className={className}>
      <a onClick={handleClick} data-testid="external-nav" className={`${className}-link`} href={path}>
        {label}
      </a>
    </div>
  );
};

export default ExternalNavLink;
