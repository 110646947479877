/**
 * Creates a uuid string from a prefix and an id
 *
 * @param prefix string to prepend to the id - eg 'r4r_skill_'
 * @param id string to pad with zeros - eg '54'
 * @returns a full uuid string - eg 'r4r_skill_054'
 */
export function inflate(prefix?: string, id?: string) {
  if (!prefix || !id) return undefined

  switch (prefix) {
    case 'r4r_lesson_':
    case 'r4r_video_':
    case 'r4r_epub_':
    case 'r4r_aud_':
      return `${prefix}${id.padStart(5, '0')}`
    default:
      return `${prefix}${id.padStart(3, '0')}`
  }
}

/**
 * Creates a an id as an integer string from a uuid string
 *
 * @param id string to deflate to an integer string - eg 'r4r_skill_054'
 * @returns
 */
export function deflate(id?: string) {
  if (!id) return undefined
  const idNumber = id.split('_').pop() || ''
  return parseInt(idNumber, 10).toString()
}
