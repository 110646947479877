import React from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import './Popover.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface PopoverProps {
  child: React.ReactNode;
  header: string;
  onOpenChange: (open: boolean) => void;
  isPopupOpened: boolean;
  onOpenAutoFocus?: ((event: Event) => void) | undefined;
  ariaLabel?: string;
  menuRef?: React.LegacyRef<HTMLButtonElement> | undefined;
}
const className = 'popover';
const Popover = ({
  child,
  header,
  isPopupOpened,
  onOpenChange,
  onOpenAutoFocus,
  ariaLabel,
  menuRef
}: PopoverProps) => {
  return (
    <RadixPopover.Root open={isPopupOpened} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger asChild>
        <RadixPopover.PopoverAnchor>
          <button
            id={`popoverTarget_${header}`}
            className={`${className}__trigger`}
            aria-label={ariaLabel}
            aria-expanded={isPopupOpened}
            data-testid="popoverTrigger"
            ref={menuRef}
          >
            <div dangerouslySetInnerHTML={{ __html: header }} />
            <FontAwesomeIcon
              aria-hidden
              role={'img'}
              icon={faChevronDown}
              style={{ transform: isPopupOpened ? 'rotate(180deg)' : '' }}
            />
          </button>
        </RadixPopover.PopoverAnchor>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          onOpenAutoFocus={onOpenAutoFocus}
          align="start"
          className={`${className}__content`}
          sideOffset={5}
          id={`${className}__content`}
        >
          {child}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

export default Popover;
