import React, { useMemo, useState } from 'react';
import Select from '../Select/Select';
import { useQuery } from 'react-query';
import { getFilterGroupsByModule, ModuleId } from 'api-library';
import './FilterByDropdown.scss';

interface FilterByDropdownProps {
  moduleId: string;
  filterType: string;
  dataType: string;
  initialFilterId: string;
  onFilterSelection: (filterGroup: string) => void;
}

const FilterByDropdown = (props: FilterByDropdownProps) => {
  const className = 'filter-dropdown';

  const [initialSelection, setSelection] = useState(0);

  const getFilterGroup = () => {
    return useQuery(['getFilterGroupsByModule', props.moduleId], () =>
      getFilterGroupsByModule(props.moduleId)
    );
  };

  const { data: groupData } = getFilterGroup();

  const getGroupsFromArray = (groups: any[], uuidFieldName: string) => {
    const optionData = groups.map((group, index) => {
      const uuid = group[uuidFieldName];
      if (props.initialFilterId && props.initialFilterId === uuid) {
        setSelection(index + 1);
      }
      return {
        id: uuid,
        content: group.title,
        value: uuid
      };
    });
    if (!props.initialFilterId) {
      setSelection(0);
    }
    return [
      {
        id: '0',
        content: `All ${props.dataType}`,
        value: ''
      },
      ...optionData
    ];
  };

  const options = useMemo(() => {
    if (groupData) {
      return props.moduleId === ModuleId.R4R_RTK.toString()
        ? getGroupsFromArray(groupData[0].nestedGroups, 'assetUuid')
        : getGroupsFromArray(groupData, 'groupId');
    } else {
      return [];
    }
  }, [groupData, props.initialFilterId, props.dataType]);

  return (
    <div className={className}>
      {options && options.length > 0 && (
        <Select
          options={options}
          onSelect={props.onFilterSelection}
          defaultText={`Filter By ${props.filterType}`}
          initialSelected={initialSelection}
          dropdownStyles={{
            maxHeight: '45vh'
          }}
          screenReaderDescription={`Filter By ${props.filterType}`}
        />
      )}
    </div>
  );
};

export default FilterByDropdown;
