import type { SVGProps } from 'react'

export default function Video(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 320 190" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
      <title>video asset</title>
      <g>
        <rect width="100%" height="100%" className="background-fill" />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.501 51.5454C252.767 51.5454 263.6 62.5399 263.946 76.193L263.955 76.8666V79.0909H291.5C293.919 79.0909 295.903 80.9649 296.078 83.3393L296.091 83.6818V108.39L326.173 93.3479C327.596 92.6363 329.288 92.7098 330.642 93.5499C331.89 94.3212 332.688 95.6401 332.804 97.0896L332.818 97.4545V180.091C332.818 181.682 331.994 183.16 330.642 183.995C329.903 184.452 329.065 184.682 328.227 184.682C327.665 184.682 327.103 184.579 326.569 184.372L326.173 184.197L296.091 169.155V193.864C296.091 196.283 294.217 198.267 291.842 198.442L291.5 198.454H153.773C151.354 198.454 149.37 196.581 149.194 194.206L149.182 193.864V83.6818C149.182 81.2628 151.056 79.2788 153.43 79.1035L153.773 79.0909H254.773V76.8666C254.773 68.1525 248.206 61.0296 240.025 60.7366L239.501 60.7272H185.909C183.375 60.7272 181.318 58.6705 181.318 56.1363C181.318 53.7173 183.192 51.7334 185.567 51.558L185.909 51.5454H239.501ZM286.909 88.2727H158.364V189.273H286.909V88.2727ZM323.636 104.883L296.091 118.655V158.89L323.636 172.663V104.883ZM259.364 152.545C261.898 152.545 263.955 154.602 263.955 157.136C263.955 159.555 262.081 161.539 259.706 161.715L259.364 161.727H185.909C183.375 161.727 181.318 159.671 181.318 157.136C181.318 154.717 183.192 152.733 185.567 152.558L185.909 152.545H259.364Z"
            className="foreground-fill"
          />
        </g>
      </g>
    </svg>
  )
}
