import React, { useCallback, useContext, useMemo, useState } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { uniqueId } from 'lodash-es';
import { NonNegativeInteger } from 'type-fest';
import SuccessIcon from '../../assets/images/success-notification.svg';
import ErrorIcon from '../../assets/images/error-notification.svg';
import WarningIcon from '../../assets/images/warning-notification.svg';
import InfoIcon from '../../assets/images/info-notification.svg';
import XCloseIconX from '../../assets/images/nav-close.svg';
import { useTimeout } from '../../hooks/useTimeout/useTimeout';
import styles from './ToastOutlet.module.scss';
import { StoreAction, StoreContext } from '../../store/store';

interface ToastOutletProps<T extends number> {
  /**
   * Number of milliseconds to wait before toast expires
   *
   * @default 5000
   */
  duration?: NonNegativeInteger<T>;
}

interface UseToastReturn {
  /** Show success toast notification */
  success: (content: string) => string;
  /** Show error toast notification */
  error: (content: string) => string;
  /** Show warning toast notification */
  warning: (content: string) => string;
  /** Show info toast notification */
  info: (content: string) => string;
}

export interface ToastItem {
  /** Unique Id of toast */
  id: string;
  /** Message to display */
  message: string;
  /** Status of toast */
  status: Status;
}

export type Status = 'success' | 'error' | 'warning' | 'info';

interface ToastProps<T extends number> {
  /**
   * Callback to be called when the toast closes
   */
  onClose?: () => void;
  /**
   * Toast status type
   */
  status?: Status;
  /**
   * Duration in ms to show the toast before hiding
   */
  duration?: NonNegativeInteger<T>;
  /**
   * Content to display on toast
   */
  children: React.ReactNode;
}

export const useToast = (): UseToastReturn => {
  const { dispatch } = useContext(StoreContext);
  const showToast = useCallback(
    (message: string, status: Status) => {
      const id = uniqueId('toast-');
      dispatch({ type: StoreAction.SET_TOAST_ITEM, data: { id, message, status } });
      return id;
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      success: (content: string) => showToast(content, 'success'),
      error: (content: string) => showToast(content, 'error'),
      warning: (content: string) => showToast(content, 'warning'),
      info: (content: string) => showToast(content, 'info')
    }),
    [showToast]
  );
};

const ToastOutlet = <T extends number>({ duration = 5000 as NonNegativeInteger<T> }: ToastOutletProps<T>) => {
  const { state, dispatch } = useContext(StoreContext);
  const toast = state?.toastsItem;
  return (
    <ToastPrimitive.Provider swipeDirection="down" swipeThreshold={30}>
      {toast && toast.id && (
        <Toast
          key={toast.id}
          status={toast.status}
          duration={duration}
          onClose={() => {
            dispatch({ type: StoreAction.DELETE_TOAST_ITEM, data: toast.id });
          }}
        >
          {toast.message}
        </Toast>
      )}
      <ToastPrimitive.Viewport className={styles.toastViewport} role="status" aria-live="polite" />
    </ToastPrimitive.Provider>
  );
};

const toastStatusToIcon = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon
} as const;

export const Toast = <T extends number>({
  onClose,
  duration = 5000 as NonNegativeInteger<T>,
  children,
  status = 'info'
}: ToastProps<T>) => {
  const Icon = toastStatusToIcon[status];
  const [delay, setDelay] = useState<number | undefined>(duration);
  const [open, setOpen] = useState(true);

  const closeToast = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      // wait for exit animation to finish before notifying close callback
      onClose?.();
    }, parseInt(styles.exitAnimationTime));
  }, [onClose]);

  useTimeout(closeToast, delay);

  return (
    <ToastPrimitive.Root
      className={styles.toastRoot}
      data-status={status}
      aria-live="polite"
      onMouseEnter={() => setDelay(undefined)}
      onMouseLeave={() => setDelay(duration)}
      onEscapeKeyDown={closeToast}
      onSwipeEnd={closeToast}
      open={open}
    >
      <img className={`${styles.icon}`} src={Icon} alt={'X'} aria-hidden />
      <ToastPrimitive.Description className={styles.toastDescription}>{children}</ToastPrimitive.Description>
      <ToastPrimitive.Close className={styles.closeButton} aria-label="close" onClick={closeToast}>
        <img className={styles.closeButtonFilter} src={XCloseIconX} alt={'X'} aria-hidden />
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  );
};

export default ToastOutlet;
