import React, { useRef, useState } from 'react';
import { ModuleId, getLessonsByModule } from 'api-library';
import { ModuleTile } from '../../ModuleTile/ModuleTile';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import './MegaMenu.scss';
import { deflate } from '../../../utilities/uuid';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Markup } from 'interweave';
import { ModuleName } from '../../../pages/Module/Module';

interface MegaMenuProps {
  currentLesson: string;
  currentModule: string;
  availableModules: string[];
  closeFunction: () => void;
}

/**
 * MegaMenu component
 *
 * @component
 * @param {string} currentLesson - current Lesson shown on the page
 * @param {string} currentModule - current Module that contains the current lesson
 * @param {Array} availableModules - full list of modules to display
 * @param {function} closeFunction - function to close the menu from the button
 *
 * @example
 * <MegaMenu currentLesson={"ABCs"} currentModule={'R4R_RTK'} closeFunction={closeMenu}/>
 *
 * @return popup containing module selection and lessons
 */

enum QueryIdToModuleId {
  'r4r_module_001' = 'R4R_WBTP',
  'r4r_module_002' = 'R4R_SRD',
  'r4r_module_003' = 'R4R_RTK'
}

const MegaMenu = (props: MegaMenuProps) => {
  const className = 'mega-menu';
  const topElementRef = useRef<HTMLDivElement>(null);
  const topButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedModule, setSelectedModule] = useState<string>(props.currentModule);
  const moduleName = QueryIdToModuleId[selectedModule as keyof typeof QueryIdToModuleId];
  const firstModuleRef = useRef<HTMLButtonElement>(null);
  const firstLessonRef = useRef<HTMLAnchorElement>(null);

  const { data: lessonData } = useQuery(['getLessonsByModuleId', selectedModule], () =>
    getLessonsByModule(selectedModule, 1000)
  );

  const lessons = lessonData?.results;

  const handleLessonClick = (uuid: string) => {
    props.closeFunction();
  };

  React.useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        props.closeFunction();
      }
    });
    firstModuleRef.current?.focus();
  }, []);

  const generateLessons = (lesson: any, index: number) => {
    const isCurrent = lesson.title === props.currentLesson;
    return (
      <li className={`category-lesson`} role={'menuitem'}>
        <Link
          className={`category-lesson-link ${isCurrent ? 'current' : ''}`}
          key={`button-${lesson.uuid}`}
          onClick={() => handleLessonClick(lesson.uuid)}
          to={`/lesson/${deflate(lesson.uuid)}`}
          data-testid={'lesson-' + lesson.uuid}
          ref={index === 0 ? firstLessonRef : null}
          aria-current={isCurrent}
        >
          <Markup content={lesson.title} />
        </Link>
      </li>
    );
  };

  const determineModuleClass = (moduleOption: string) => {
    let moduleClass = '';
    if (selectedModule === moduleOption) {
      moduleClass += 'active';
    } else {
      moduleClass += 'inactive';
    }
    return moduleClass;
  };

  const scrollToTop = () => {
    if (topElementRef.current) {
      topElementRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      firstLessonRef.current?.focus();
    }
  };

  const showScrollButton = () => {
    if (topButtonRef.current && topElementRef.current) {
      if (topElementRef.current.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        topButtonRef.current.style.display = 'block';
      } else {
        topButtonRef.current.style.display = 'none';
      }
    }
  };

  const getModuleButtons = () => {
    return props.availableModules.map((module, index) => {
      if (module === ModuleId.R4R_WBTP || module === ModuleId.R4R_RTK || module === ModuleId.R4R_SRD) {
        const moduleName = QueryIdToModuleId[module as keyof typeof QueryIdToModuleId];
        const moduleClass = determineModuleClass(module);
        const classModifier = moduleClass === 'active' ? ' ' + moduleName : '';
        return (
          <button
            className={`${className}__option ${moduleClass} ${classModifier}`}
            onClick={() => setSelectedModule(module)}
            data-testid={`option-${moduleName}-${moduleClass}`}
            ref={index === 0 ? firstModuleRef : null}
            aria-label={`${ModuleName[moduleName]} lessons`}
            aria-controls={`${className}__${moduleName}-lesson-options`}
            aria-expanded={moduleClass === 'active'}
            role={'listitem'}
          >
            <ModuleTile moduleId={ModuleId[moduleName]} />
          </button>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={className} data-testid={className}>
      <div className={`${className}__content`} id={'mega-menu-content'}>
        <div className={`${className}__modules`}>
          <section id="module-buttons" title="Lesson Modules">
            <div role={'list'}>{getModuleButtons()}</div>
          </section>
        </div>
        <div
          id={`${className}__${moduleName}-lesson-options`}
          className={`${className}__lesson-options ${moduleName}`}
          data-testid={'lesson-options'}
          ref={topElementRef}
          onScroll={showScrollButton}
        >
          <div className={`${className}__category `}>
            <section id={'lesson-buttons'} title={'Lesson Options'}>
              <ul className={'category-lessons'} role={'menu'}>
                {lessons &&
                  lessons.map((lesson, index) => {
                    return generateLessons(lesson, index);
                  })}
              </ul>
            </section>
          </div>
        </div>
        <button
          className={`${className}__scroll-up`}
          data-testid={'scroll-button'}
          onClick={scrollToTop}
          ref={topButtonRef}
          aria-label="jump to top of lessons"
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
      </div>
    </div>
  );
};

export default MegaMenu;
