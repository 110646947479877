import type { Table, TableCell, TableRow } from '../types';

import './Table.scss';
import classNames from 'classnames';

const className = 'lesson-content-table';
const outerClassName = 'lesson-content-table-outer';
const cellClassName = `${className}-cell`;
const rowClassName = `${className}-row`;
const bodyClassName = `${className}-body`;
const headerClassName = `${className}-header`;
const twoColumnClassName = `${cellClassName}__two-column`;

export interface TableProps {
  /**
   * The full content JSON asset object of type `table`
   */
  element: Table;
  /**
   * The content parser's generateElement function is passed in to allow for recursive parsing of
   * child elements.
   */
  generateElement: (element: any, key: any) => any;
}

/**
 * Displays a Table component within the lesson content parser. Not meant to be used out of the
 * lesson content parser context.
 *
 * @component
 * @param props
 *
 * @example
 *  <Table
 *    element={tableElement}
 *    generateElement={generateElement}
 *  />
 *
 * @return a content parser compatible Table component
 */

export default function Table({ element, generateElement }: TableProps) {
  const entries = element.children.reduce(
    (acc: Array<TableRow[]>, curr: TableRow) => {
      const [headerRows, bodyRows] = acc;
      if (curr.is_header) {
        return [[...headerRows, curr], bodyRows];
      }
      return [headerRows, [...bodyRows, curr]];
    },
    [[], []]
  );

  const [theadRows, tbodyRows] = entries;

  return (
    <div className={outerClassName}>
      <table className={className}>
        {theadRows.length > 0 && (
          <thead className={headerClassName}>
            {theadRows.map((row: TableRow, rowIndex: number) => {
              return (
                <tr key={rowIndex} className={rowClassName}>
                  {row.children.map((cell: TableCell, cellIndex: number) => {
                    if (cell.children.length === 0) {
                      return <td key={cellIndex} className={cellClassName}></td>;
                    } else {
                      return (
                        <th key={cellIndex} className={cellClassName}>
                          {cell.children.map((cellChild, cellChildIndex) =>
                            generateElement(cellChild, cellChildIndex)
                          )}
                        </th>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </thead>
        )}
        <tbody className={bodyClassName}>
          {tbodyRows.map((row: TableRow, rowIndex: number) => {
            return (
              <tr key={rowIndex} className={rowClassName}>
                {row.children.map((cell: TableCell, cellIndex: number) => {
                  const isTwoColumn = row.children.length === 2;
                  return (
                    <td
                      key={cellIndex}
                      className={classNames(cellClassName, isTwoColumn && twoColumnClassName)}
                    >
                      {cell.children.map((cellChild, cellChildIndex) =>
                        generateElement(cellChild, cellChildIndex)
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
