import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'authentication-library';

import router from './pages/router';
import './assets/fonts/MuseoSans/font.scss';
import { datadogRum } from '@datadog/browser-rum';
import { getSignedCookies } from 'api-library';
import { getAnalyticsEnv } from './utilities/DumbleData';
import { StateProvider } from './store/store';
datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
  env: process.env.REACT_APP_DATADOG_RUM_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: false,
  trackLongTasks: false,
  defaultPrivacyLevel: 'mask'
});

const rootElement = document.getElementById('root') as HTMLElement;

getSignedCookies().catch((error) => console.log(error));

const cacheTime = process.env.REACT_APP_CACHE_DURATION
  ? Number(process.env.REACT_APP_CACHE_DURATION)
  : 1000 * 60 * 5; // five minutes

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: cacheTime,
      cacheTime: cacheTime
    }
  }
});
const headerScript = document.createElement('script');
if (getAnalyticsEnv() === 'production') {
  headerScript.src = 'https://assets.adobedtm.com/119ebaf99f7d/ba00649e0378/launch-0dd3a35c41b8.min.js';
} else {
  headerScript.src =
    'https://assets.adobedtm.com/119ebaf99f7d/ba00649e0378/launch-e57c90234c70-development.min.js';
}
headerScript.addEventListener('load', (event) => {
  const pageBottomScript = document.createElement('script');
  pageBottomScript.innerHTML = '_satellite.pageBottom();';
  rootElement?.parentElement?.appendChild(pageBottomScript);
});
document.head.appendChild(headerScript);
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider app="TEACHER_HUB">
        <StateProvider>
          <RouterProvider router={router} />
        </StateProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
