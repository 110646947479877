import React, { useEffect, useState } from 'react';
import { ModuleTile } from '../ModuleTile/ModuleTile';
import DisplayPanel from '../DisplayPanel/DisplayPanel';
import './LessonsDisplayPanel.scss';
import { AssetApiData, AssetByModule, ModuleId } from 'api-library';

interface LessonPanelProps {
  title?: string;
  lessonAssets?: AssetByModule[];
  skillsMapData?: AssetApiData;
}

interface LessonChildPanelProps {
  columnCount: number;
  lessonAssets?: AssetByModule[];
  key: number;
}

const moduleDataMap = new Map([
  [
    ModuleId.R4R_RTK,
    {
      borderColor: '#55B700',
      backgroundColor: 'rgba(95, 200, 13, 0.1)',
      header: <ModuleTile moduleId={ModuleId.R4R_RTK} />,
      title: 'Read To Know Text Sets'
    }
  ],
  [
    ModuleId.R4R_SRD,
    {
      borderColor: '#00AAD8',
      backgroundColor: 'rgba(0, 170, 216, 0.1)',
      header: <ModuleTile moduleId={ModuleId.R4R_SRD} />,
      title: 'Short Reads Decodables'
    }
  ],
  [
    ModuleId.R4R_WBTP,
    {
      borderColor: '#FFB900',
      backgroundColor: 'rgba(255, 185, 0, 0.1)',
      header: <ModuleTile moduleId={ModuleId.R4R_WBTP} />,
      title: 'Wiley Blevins Teaching Phonics'
    }
  ],
  [
    ModuleId.R4R_UA,
    {
      borderColor: '#FF7D9C',
      backgroundColor: 'rgb(255,221,229, 0.9)',
      header: <ModuleTile moduleId={ModuleId.R4R_UA} />,
      title: 'Universal Assets'
    }
  ]
]);

/**
 * Component for creating a lessons display panel
 *
 * @component
 * @param LessonPanelProps details about the Lessons Display Panel
 * @param PanelProps.lessonAssets uses lessonAssets to display the cards
 *
 * @example
 * <LessonsDisplayChildPanel
 *    columnCount={lessonAssets.length}
 *    lessonAssets={[]}
 *  />
 *
 * @return a component displaying a panel of cards by fetching data associated with the given title
 */
export const LessonsDisplayChildPanel = (childProps: LessonChildPanelProps) => {
  if (!childProps.lessonAssets) {
    return null;
  }
  return (
    <>
      <div
        className={'display-panel-item' + childProps.columnCount}
        data-testid={'display-panel-item' + childProps.columnCount}
      >
        {childProps.lessonAssets.map((item) => {
          const moduleData = moduleDataMap.get(item.uuid);
          if (!moduleData) {
            return null;
          }
          const { header, backgroundColor, borderColor, title } = moduleData;
          return (
            <DisplayPanel
              data={item.assets}
              color={backgroundColor}
              borderColor={borderColor}
              header={header}
              key={item.uuid}
              title={title}
            />
          );
        })}
      </div>
    </>
  );
};

/**
 * Method is used to check the lenght of the column elements of all the modules and
 *  returns the position of the column which has less size
 *
 * @param childCountArray[] holds the total size of elements in one column
 * @return wrapLimit contains the column count
 * @newChildCount is used to increment the size of the small count position for next time calculation.
 *
 *
 *
 * @return a position of the column which has less size.
 */
function getLessChildCountPos(childCountArray: number[], wrapLimit: number, newChildCount: number) {
  let smallPos = 0;
  let count = childCountArray[0];
  for (let pos = 0; pos < wrapLimit; pos++) {
    if (childCountArray[pos] < count) {
      count = childCountArray[pos];
      smallPos = pos;
    }
  }
  childCountArray[smallPos] = childCountArray[smallPos] + newChildCount;
  return smallPos;
}

/**
 * Method to arrange the grid elements in masonry style. We are using flex CSS functionality
 * to arrange the elements in multidimensional
 * Flex is single dimensional, but to create 2D style we are writing nested flex containers.
 * This method helps to set the elements for required order to achieve multidimensional order.
 * This logic works for 4 modules if we add more need to work on this.
 *
 * @param AssetByModule[] uses lessonAssets to display the cards
 * @return AssetByModule[ ][ ] returns the 2D array where each array refers to cards that fits in one column elements
 * @example
 *
 * TEACHING PHONICS  | DECODABLES | TEST SETS
 * READY FOR READING |
 *
 * READY FOR READING module will be fit in either of the 3 columns which has fewer elements.
 *
 * @return a two-dimensional array of assets with custom order
 */
function arrangeAssetsByCustomOrder(lessonAssets: AssetByModule[], wrapLimit: number) {
  const colWrapLimit = wrapLimit;
  let rowCount = 0;
  const childCountArray = [colWrapLimit];

  const columnOrderedAssets: AssetByModule[][] = Array.from(
    Array(lessonAssets.length < colWrapLimit ? lessonAssets.length : colWrapLimit),
    () => new Array(Math.round(lessonAssets.length / colWrapLimit) + 1)
  );

  let prevSmallCountPosition;
  for (let colPosition = 0; colPosition < lessonAssets.length; colPosition++) {
    if (colPosition < colWrapLimit) {
      //first row elements
      columnOrderedAssets[colPosition][rowCount] = lessonAssets[colPosition];
      childCountArray[colPosition] = lessonAssets[colPosition].assets.length;
    } else {
      //Next row elements
      if (colPosition % colWrapLimit === 0) {
        rowCount++;
        prevSmallCountPosition = undefined;
      }
      const smallCountPosition = getLessChildCountPos(
        childCountArray,
        colWrapLimit,
        lessonAssets[colPosition].assets.length
      );
      // used to increase the row number and set dynamically if inserts happens on the  same column
      const rowCountPosition = !prevSmallCountPosition
        ? prevSmallCountPosition === smallCountPosition
          ? rowCount + 1
          : rowCount
        : rowCount;

      columnOrderedAssets[smallCountPosition][rowCountPosition] = lessonAssets[colPosition];
      prevSmallCountPosition = smallCountPosition;
    }
  }
  return columnOrderedAssets;
}

/**
 * Utility method to return list of arrays where each array contanins the modules belongs to one column
 * @param lessonOrderedAssets details about the Lessons in 2D array
 * @return lessons uses lessonAssets to display the cards
 */
function getLessons(lessonOrderedAssets: AssetByModule[][]) {
  return lessonOrderedAssets.map((lesson, index) => {
    return { row: index, assets: lesson };
  });
}

/**
 * Utility method to return the wrap limit based the window size
 * @param innerWidth is the window.innerWidth
 * @return wraplimit 3 or 2 or 1
 */
function getWrapLimit(innerWidth: number) {
  //886 or 769
  return innerWidth >= 1227 ? 3 : innerWidth > 886 ? 2 : 1;
}

/**
 * Component for creating a lessons display panel
 *
 * @component
 * @param LessonPanelProps details about the Lessons Display Panel
 * @param PanelProps.title uses title to set the title and to fetch data regarding a skill to be displayed
 * @param PanelProps.lessonAssets uses lessonAssets to display the cards
 *
 * @example
 * <LessonsDisplayPanel
 *    title={"Alphabet"}
 *    lessonAssets={[]}
 *  />
 *
 * @return a component displaying a panel of cards by fetching data associated with the given title
 */

const LessonsDisplayPanel = ({ title, lessonAssets }: LessonPanelProps) => {
  const [wrapLimit, setWrapLimit] = useState(getWrapLimit(window.innerWidth));
  const setColWrapLimit = () => {
    setWrapLimit(getWrapLimit(window.innerWidth));
  };
  useEffect(() => {
    window.addEventListener('resize', setColWrapLimit);
    return () => {
      window.removeEventListener('resize', setColWrapLimit);
    };
  }, []);
  if (!lessonAssets || !lessonAssets.length) {
    return null;
  }
  const cssClass = 'lessons-display-panel';
  return (
    <div className={cssClass + '__container'} data-testid={cssClass + '__container'}>
      <div className={cssClass + '__header'} data-testid={cssClass + '__header'}>
        <h2 className={cssClass + '__page-title'} data-testid={cssClass + '__page-title'}>
          {title}
        </h2>
      </div>
      <div
        className={`${cssClass}__display-panel-container`}
        data-testid={cssClass + '__display-panel-container'}
      >
        {getLessons(arrangeAssetsByCustomOrder(lessonAssets, wrapLimit)).map(({ row, assets }) => {
          return (
            <LessonsDisplayChildPanel columnCount={lessonAssets.length} lessonAssets={assets} key={row} />
          );
        })}
      </div>
    </div>
  );
};
export default LessonsDisplayPanel;
