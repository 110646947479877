import React, { ReactElement } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import XCloseIcon from '../../assets/images/nav-close.svg';
import styles from './Drawer.module.scss';
import { Button } from '@scholastic/mockingjay';

type DrawerChildProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type DrawerChild = React.ReactElement<DrawerChildProps>;

export interface DrawerProps {
  /**
   * Header title
   */
  title?: string;
  /**
   * Header instructions in large text
   */
  mainInstructions?: string;
  /**
   * Header instructions in smaller text
   */
  secondaryInstructions?: string;
  /**
   * Header icon
   */
  headerIcon?: ReactElement;
  /**
   * Trigger icon
   */
  triggerIcon?: ReactElement;
  /**
   * Trigger button title
   */
  triggerTitle?: string;
  /**
   * Trigger button with styling
   */
  triggerButton?: React.ReactNode;
  /**
   * Drawer content
   */
  children?: DrawerChild[] | DrawerChild;
  /**
   * Styling for the trigger button (layout, button customization)
   */
  className?: string;
  /**
   * No-op for trigger button
   */
  disabled?: boolean;
  /**
   * Controlled drawer passes down setter (open/close) to child
   */
  controlled?: boolean;
  onCancel?: () => void;
  onApply?: () => void;
  applyButtonDisabled?: boolean;
  onDrawerChange?: (change: boolean) => void;
  openControl?: boolean;
  contentId?: string;
}

const Drawer = ({
  title,
  mainInstructions,
  secondaryInstructions,
  headerIcon,
  triggerIcon,
  triggerTitle,
  triggerButton,
  children,
  className,
  disabled,
  controlled,
  onCancel,
  onApply,
  applyButtonDisabled,
  onDrawerChange,
  openControl,
  contentId
}: DrawerProps) => {
  const handleOpenChange = (openChange: boolean) => {
    if (onDrawerChange) {
      onDrawerChange(openChange);
    }
  };

  const dialogProps = controlled ? { open: openControl, onOpenChange: handleOpenChange } : {};

  const getTrigger = () => {
    if (triggerButton) {
      return triggerButton;
    } else {
      return (
        <button title={triggerTitle} className={classNames(styles.triggerButton, className)}>
          {triggerIcon ? (
            <span className={styles.triggerIcon} data-testid="icon" aria-hidden>
              {triggerIcon}
            </span>
          ) : null}
          <span>{triggerTitle}</span>
        </button>
      );
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleApply = () => {
    if (onApply) {
      onApply();
    }
  };

  return (
    <Dialog.Root {...dialogProps}>
      <Dialog.Trigger asChild disabled={disabled}>
        {getTrigger()}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />

        <Dialog.Content
          className={styles.content}
          id={contentId}
          aria-describedby="manage-drawer-description"
        >
          <div className={styles.childrenContainer}>
            <div className={styles.contentHeader}>
              <div className={styles.titleRow}>
                <div className={styles.titleHeader}>
                  <div className={styles.titleDivider} />
                  <div className={styles.iconBackground} aria-hidden>
                    {headerIcon && (
                      <span data-testid="title-icon" className={styles.titleTriggerIcon}>
                        {headerIcon}
                      </span>
                    )}
                  </div>
                  <div className={styles.titleDivider} />
                </div>
                <Dialog.Title className={styles.title}>{title}</Dialog.Title>
                <div id="manage-drawer-description" className={styles.instructions}>
                  {mainInstructions && <p className={styles.mainInstructions}>{mainInstructions}</p>}
                  {secondaryInstructions && (
                    <p className={styles.secondaryInstructions}>{secondaryInstructions}</p>
                  )}
                </div>
              </div>
              <div className={styles.closeContainer}>
                <Dialog.Close className={styles.dialogTrigger} asChild>
                  <button
                    onClick={onCancel}
                    className={styles.buttonClose}
                    aria-label="Close"
                    title="Close the dialog"
                  >
                    <img className={`${styles.buttonClose}__img`} src={XCloseIcon} alt="close" aria-hidden />
                  </button>
                </Dialog.Close>
              </div>
            </div>
            <div className={styles.children}>
              {controlled
                ? React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                      return React.cloneElement(child);
                    } else {
                      return child;
                    }
                  })
                : children}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.buttonRow}>
              <Button onClick={handleCancel} className={`styledButton secondary ${styles.footerButton}`}>
                Cancel
              </Button>
              <Button
                onClick={handleApply}
                className={`styledButton primary ${styles.footerButton}`}
                disabled={applyButtonDisabled}
              >
                Apply
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Drawer;
