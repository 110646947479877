import React, { ReactElement } from 'react'
import './AssetCard.scss'
import { Card } from 'component-library'

interface AssetCardProps {
  imageSrc: string
  imageAlt: string
  title: string
  description?: string
  href?: string
  onSelect?: () => void
}

/**
 * Component for creating a card.
 *
 * @component
 * @param AssetCardProps details about the Asset Card
 * @param AssetCardProps.imageSrc the link pointing to the image displayed on the asset card
 * @param AssetCardProps.imageAlt a string that describes the image
 * @param AssetCardProps.text a string with text to be displayed beneath the image
 * @param AssetCardProps.description a string with an optional description of the card
 * @param AssetCardProps.href makes the card a clickable button if one is passed
 * @param AssetCardProps.onSelect callback function that fires a function when the card is clicked
 *
 * @example
 * <AssetCard
 *         imageSrc={"https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png"}
 *         imageAlt={"Google Logo"}
 *         href={"http://www.google.com"}
 * />
 *
 * @return a card component displaying an image and text according to the specifications that are denoted in the props
 */

const AssetCard = (props: AssetCardProps) => {
  const htmlClass = 'asset-card'
  const getContent = (imageSrc: string, imageAlt: string, title: string): ReactElement => {
    return (
      <div className={htmlClass + '__content'} data-testid={htmlClass + '__content'}>
        <div className={htmlClass + '__image-container'}>
          {
            <img
              className={htmlClass + '__image'}
              data-testid={htmlClass + '__image'}
              src={imageSrc}
              alt={imageAlt}
            />
          }
        </div>
        <div className={htmlClass + '__text-container'} data-testid={htmlClass + '__text-container'}>
          <p className={htmlClass + '__title'}>{title}</p>
        </div>
      </div>
    )
  }
  return (
    <div className={`${htmlClass}__container`} data-testid={htmlClass + '__container'}>
      <Card
        content={getContent(props.imageSrc, props.imageAlt, props.title)}
        onSelect={props.onSelect}
        href={props.href}
        hasRoundedBorders={true}
      />
    </div>
  )
}

export default AssetCard
