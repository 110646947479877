import { useQuery } from 'react-query'
import { useAuth } from 'authentication-library'
import { getSkillCategories } from 'api-library'

export default function useSkillCategories() {
  const { modules } = useAuth()
  return useQuery(['skillCategories', modules.toString()], () => {
    return getSkillCategories(modules)
  })
}
