import React from 'react';

import './App.scss';
import './styles/components/buttons.scss';
import { Outlet } from 'react-router-dom';
import { GlobalFooter, GlobalHeader } from 'component-library';
import useSubHeader from './utilities/useSubHeader';
import { emitDirectCall } from './utilities/DumbleData';
import ToastOutlet from './components/ToastOutlet/ToastOutlet';

function App() {
  const SubHeader = useSubHeader();

  return (
    <div className="teacher-hub">
      <ToastOutlet duration={5000} />
      <div className="teacher-hub-header">
        <GlobalHeader
          onIconClick={() => {
            emitDirectCall('r4rIcon');
          }}
        >
          <></>
        </GlobalHeader>
        <SubHeader />
      </div>
      <main id="main-content" className="teacher-hub-content">
        <Outlet />
      </main>
      <div className="teacher-hub-footer">
        <GlobalFooter />
      </div>
    </div>
  );
}
export default App;
