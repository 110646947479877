import React, { ReactElement } from 'react';
import './SkillNavigationPanel.scss';
import AssetCard from '../AssetCard/AssetCard';
import { deflate } from '../../utilities/uuid';
import { useNavigate } from 'react-router-dom';
import { emitDirectCall } from '../../utilities/DumbleData';

interface Skill {
  uuid: string;
  name: string;
  thumbnailAsset: {
    filename: string;
    metadata: string;
  };
}

export interface SkillNavigationPanelProps {
  skillData: Skill[];
}

/**
 * Component for creating Skills Navigation Panel
 *
 * @example
 * <SkillNavigationPanel />
 *
 * @return a component displaying Asset Cards using given data in a grid view
 */

const getAltTextFromMetaData = (metadata: string): string => {
  try {
    return JSON.parse(metadata).alt_text;
  } catch (error) {
    return '';
  }
};

const SkillNavigationPanel = ({ skillData }: SkillNavigationPanelProps) => {
  const cssClass = 'skill-navigation-panel';
  const navigate = useNavigate();

  const getData = (): ReactElement => {
    return (
      <div className={cssClass + '__cards-container'} data-testid={cssClass + '__cards-container'}>
        {skillData.map(({ uuid, name, thumbnailAsset }) => {
          const imgSrc = `${process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN}/${thumbnailAsset.filename}`;
          const altText = getAltTextFromMetaData(thumbnailAsset.metadata);

          return (
            <AssetCard
              imageSrc={imgSrc}
              imageAlt={altText}
              title={name}
              onSelect={() => {
                emitDirectCall('browseSkills', { name });
                navigate(`/skills/${deflate(uuid)}`);
              }}
              key={uuid}
            />
          );
        })}
      </div>
    );
  };
  return (
    <div className={cssClass}>
      <div className={cssClass + '__header'} data-testid={cssClass + '__header'}>
        <h2 className={cssClass + '__skills-heading'}>Browse by skills</h2>
      </div>
      {getData()}
    </div>
  );
};

export default SkillNavigationPanel;
