import React from 'react'
import { useMatches, Params } from 'react-router-dom'

interface HeaderHandle {
  header?: typeof React.Component
}

interface Match {
  id: string
  pathname: string
  params: Params<string>
  data: unknown
  handle: HeaderHandle
}

function useSubHeader() {
  const matches = useMatches() as Match[]
  const headerMatches = matches.filter((match) => Boolean(match?.handle?.header))
  const Header = headerMatches[0]?.handle?.header ?? React.Fragment
  return Header
}

export default useSubHeader
