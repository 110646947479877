import { getImageSource } from '../utilities'

import './HtmlImage.scss'

export interface HtmlImageProps {
  element: any
}

const className = 'html-image'

export default function HtmlImage({ element }: HtmlImageProps) {
  const imageSource = getImageSource(element)
  return (
    <div className={className}>
      <img src={imageSource} className={`${className}-img`} alt={element.image_alt_text} />
    </div>
  )
}
