import React, { ReactElement, ReactNode } from 'react';
import './SkillCard.scss';
import { Card } from 'component-library';

interface SkillCardProps {
  title: ReactNode;
  borderColor?: string;
  description?: string;
  href?: string;
  onSelect?: () => void;
}

/**
 * Component for creating a skill card.
 *
 * @component
 * @param SkillCardProps details about the Skill Card
 * @param SkillCardProps.title a string to be displayed as the title in the card component
 * @param SkillCardProps.borderColor specifies the color of the border of the card
 * @param SkillCardProps.description a string with an optional description of the card to be displayed beneath the title
 * @param SkillCardProps.href makes the card a clickable button if one is passed
 * @param SkillCardProps.onSelect callback function that fires a function when the card is clicked
 *
 * @example
 *  <SkillCard
 *    title={"Title"}
 *    borderColor={"black"}
 *    description={"Description"}
 *  />
 *
 * @return a card component displaying a title and an optional description according to the specifications that are denoted in the props
 */

const SkillCard = (props: SkillCardProps) => {
  const htmlClass = 'skill-card';
  const getContent = (title: ReactNode, description?: string): ReactElement => {
    return (
      <div className={htmlClass + '__content'} data-testid={htmlClass + '__content'}>
        <div className={htmlClass + '__text-container'} data-testid={htmlClass + '__text-container'}>
          <p className={htmlClass + '__title'} data-testid={htmlClass + '__title'}>
            {title}
          </p>
          {description && (
            <p className={htmlClass + '__description'} data-testid={htmlClass + '__description'}>
              {description}
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <li
      role="lesson-group-item"
      className={`${htmlClass}__container`}
      data-testid={htmlClass + '__container'}
    >
      <Card
        content={getContent(props.title, props.description)}
        borderColor={props.borderColor}
        onSelect={props.onSelect}
        href={props.href}
        hasRoundedBorders={true}
      />
    </li>
  );
};
export default SkillCard;
