import React from 'react'
import { Markup } from 'interweave'
import './ParsedLessonContent.scss'
import MaterialCard from '../../../components/ContentParser/MaterialCard/MaterialCard'
import CardContainer from '../../../components/ContentParser/CardContainer/CardContainer'
import Container from '../../../components/ContentParser/Container/Container'
import HtmlImage from '../../../components/ContentParser/HtmlImage/HtmlImage'
import Card from '../../../components/ContentParser/Card/Card'
import TextSet from '../../../components/ContentParser/TextSet/TextSet'
import Pill from '../../../components/ContentParser/Pill/Pill'
import Table from '../../../components/ContentParser/Table/Table'

interface ParsedLessonContent {
  content: Array<any>
}

export enum ElementTypes {
  HTML = 'html',
  HTML_IMAGE = 'html-image',
  HTML_LIST = 'html-list',
  HTML_LIST_ITEM = 'html-list-item',
  HTML_SECTION = 'html-section',
  CARD = 'card',
  CONTAINER = 'container',
  INLINE = 'inline',
  CARD_CONTAINER = 'card-container',
  MATERIAL_CARD = 'material-card',
  PILL = 'pill',
  TABLE = 'table',
  TABLE_ROW = 'table-row',
  TABLE_CELL = 'table_cell',
  TEXT_SET = 'text-set'
}

export const ParsedLessonContent = ({ content }: ParsedLessonContent) => {
  const className = 'lesson-content'

  const generateElement = (element: any, key: string) => {
    switch (element.type) {
      case ElementTypes.HTML: {
        return <Markup className={`${className}__element`} key={key} content={element.html} />
      }
      case ElementTypes.HTML_SECTION:
        return (
          <section key={key} className={`${className}__${ElementTypes.HTML_SECTION}`}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </section>
        )
      case ElementTypes.HTML_LIST:
        const ListElement = element.list_type === 'bullet_list' ? 'ul' : 'ol'
        return (
          <ListElement key={key} className={`${className}__${ElementTypes.HTML_LIST}`}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </ListElement>
        )
      case ElementTypes.HTML_LIST_ITEM:
        return (
          <li key={key}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </li>
        )
      case ElementTypes.HTML_IMAGE:
        return <HtmlImage element={element} key={key} />
      case ElementTypes.CARD:
        return (
          <Card element={element} key={key}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </Card>
        )
      case ElementTypes.CONTAINER: {
        const { body, ...restElement } = element
        return (
          <Container element={restElement} key={key}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </Container>
        )
      }
      case ElementTypes.INLINE:
        const baseClassName = `${className}__${ElementTypes.INLINE}`
        return (
          <div className={baseClassName} key={key}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </div>
        )
      case ElementTypes.CARD_CONTAINER: {
        const { body, ...restElement } = element
        return (
          <CardContainer element={restElement} key={key}>
            {body.map((child: any, childKey: any) => generateElement(child, key + '_' + childKey))}
          </CardContainer>
        )
      }
      case ElementTypes.MATERIAL_CARD:
        return <MaterialCard element={element} key={key} />
      case ElementTypes.PILL:
        return <Pill html={element.html} key={key} />
      case ElementTypes.TABLE:
        return <Table element={element} generateElement={generateElement} key={key} />
      case ElementTypes.TEXT_SET: {
        const { children, ...restElement } = element
        return (
          <TextSet element={restElement} key={key}>
            {element.children.map((child: any, childKey: any) =>
              generateElement(child, key + '_' + childKey)
            )}
          </TextSet>
        )
      }
    }
  }

  return (
    <div className={className}>{content.map((element, key) => generateElement(element, String(key)))}</div>
  )
}

export const MemoizedLessonContent = React.memo(ParsedLessonContent)
