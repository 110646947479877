import { SystemResourceAsset, AssetType } from 'api-library'
import type { AssetType as ThumbnailAssetType } from '../../../components/AssetThumbnail/AssetThumbnail'
import { deflate } from '../../../utilities/uuid'

export const AssetTypeMapping = (systemResourceAsset: SystemResourceAsset): ThumbnailAssetType => {
  switch (systemResourceAsset.type) {
    case 'PDF':
      const pdfType = JSON.parse(systemResourceAsset.metadata).pdf_type
      if (pdfType === 'assessment') {
        return 'assessment'
      }
      return 'downloadable'
    default:
      return systemResourceAsset.type.toLowerCase() as ThumbnailAssetType
  }
}

export const createNavLinkUrl = (systemResourceAsset: SystemResourceAsset): string => {
  switch (systemResourceAsset.type) {
    case AssetType.activity:
      return `?id=${systemResourceAsset.uuid}`
    case AssetType.ebook:
      return `/ebook/${deflate(systemResourceAsset.uuid)}`
    case AssetType.video:
      return `/video/${deflate(systemResourceAsset.uuid)}`
    case AssetType.image:
      return `/image/${deflate(systemResourceAsset.uuid)}`
    case AssetType.audio:
      return `/audio/${deflate(systemResourceAsset.uuid)}`
    case AssetType.lesson:
      return `/lesson/${deflate(systemResourceAsset.uuid)}`
    case AssetType.pdf:
      return `${systemResourceAsset.url}`
    default:
      return ''
  }
}
