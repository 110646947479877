import type { SVGProps } from 'react'

export default function Ebook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 320 190" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
      <title>ebook asset</title>
      <g>
        <rect width="100%" height="100%" className="background-fill" />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M260.915 35.7519C262.033 38.0267 261.094 40.779 258.82 41.8969L255.177 43.6833L278.629 50.8015L294.048 43.1479C295.471 42.4432 297.161 42.5167 298.51 43.3591C299.862 44.1946 300.682 45.6706 300.682 47.2591V171.925C300.682 173.67 299.693 175.265 298.134 176.036L229.748 209.986C219.322 214.536 210.269 216.813 202.845 216.813C197.547 216.813 193.078 215.651 189.531 213.331C185.794 210.881 181.339 205.962 181.339 196.415L181.318 81.5623C181.318 81.14 181.373 80.7314 181.481 80.3434C181.481 80.146 181.488 79.944 181.5 79.742C181.855 73.1931 187.176 66.8944 196.099 62.4642L254.77 33.6539C257.052 32.5337 259.795 33.4749 260.915 35.7519ZM291.5 54.6642L229.748 85.32C214.079 93.602 199.732 96.1706 190.502 92.4864L190.521 196.415C190.521 200.786 191.882 203.892 194.563 205.65C200.191 209.334 211.604 207.883 225.868 201.665L291.5 169.079V54.6642ZM220.966 110.23C223.501 110.23 225.557 112.287 225.557 114.821V176.459C225.557 178.993 223.501 181.05 220.966 181.05C218.432 181.05 216.376 178.993 216.376 176.459V114.821C216.376 112.287 218.432 110.23 220.966 110.23ZM243.105 49.6125L200.164 70.6979C193.585 73.9642 190.967 77.6938 190.688 80.0082L190.661 80.4461C190.657 81.0103 190.811 82.1657 192.49 83.2403C196.985 86.1165 208.34 86.2496 225.559 77.1482L266.636 56.7537L243.183 49.6399L243.105 49.6125Z"
            className="foreground-fill"
          />
        </g>
      </g>
    </svg>
  )
}
