import { useQuery } from 'react-query';
import { SystemResourceCategory, SystemResourceAsset, getSystemResourceAsset } from 'api-library';
import { useAuth } from 'authentication-library';
import { AssetTypeMapping, createNavLinkUrl } from './tabPageUtils';
import './TabPagePanel.scss';

import { ResourcePanel, ResourcePanelItem } from '../../../components/ResourcePanel/ResourcePanel';
import { useContext, useEffect } from 'react';
import updateDumbledataPage from '../../../utilities/DumbleData';
import { StoreAction, StoreContext } from '../../../store/store';

export type TabPagePanelProps = {
  systemResource: SystemResourceCategory;
  itemClickOpenNewTab?: boolean;
};

const TabPagePanel = ({ systemResource, itemClickOpenNewTab }: TabPagePanelProps) => {
  /**
   * Generic Page for the System Resources Tabs. Retrieves live data for the given SystemResourceCategory and creates a ResourcePanel
   *
   * @component
   * @param systemResource.displayName the name displayed for each "card"/ResourcePanelItem
   * @param systemResource.uuid the id for the SystemResourceCategory used to retrieve all other assets within the category
   * @param systemResource.type the type of asset
   *
   * @return use live asset data from the api to create a ResourcePanel
   */
  const { modules } = useAuth();
  const { state, dispatch } = useContext(StoreContext);

  const { data: systemResourcesAsset } = useQuery(['getSystemResourceAsset', systemResource.uuid], () =>
    getSystemResourceAsset(modules, systemResource.uuid)
  );

  useEffect(() => {
    if (!!systemResource.displayName) {
      updateDumbledataPage({
        baseName: `System Resources - ${systemResource.displayName}`,
        siteSection: 'System Resources',
        previousPageURL: state?.previousPageURL
      });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
    }
  }, [systemResourcesAsset]);

  const className = 'tab-page-panel';

  // check for null, unwrap children, create empty list if necessary
  const systemResourcesAssetList =
    systemResourcesAsset && systemResourcesAsset.length > 0 && systemResourcesAsset[0].children
      ? (systemResourcesAsset[0].children as SystemResourceCategory[])
      : ([] as SystemResourceCategory[]);

  const createResourcePanels = (systemResourceAsset: SystemResourceAsset) => {
    return (
      <ResourcePanelItem
        type={AssetTypeMapping(systemResourceAsset)}
        title={systemResourceAsset.displayName}
        size={systemResourceAsset.sizeLarge ? 'large' : 'small'}
        image={
          systemResourceAsset.thumbnailUrl ? (
            <img
              src={`${process.env.REACT_APP_AWS_CLOUDFRONT_DOMAIN}/${systemResourceAsset.thumbnailUrl}`}
              alt=""
            />
          ) : null
        }
        navigationUrl={createNavLinkUrl(systemResourceAsset)}
        openNewTab={itemClickOpenNewTab}
      />
    );
  };

  const pageDisplay = systemResourcesAssetList.map((systemResourceCategory) => {
    const systemResourceAssetList = systemResourceCategory.children as SystemResourceAsset[];
    if (systemResourceCategory === undefined) {
      return null;
    }
    return (
      <>
        <h2 className={className + '__title'}>{systemResourceCategory.displayName}</h2>
        <ResourcePanel>{systemResourceAssetList.map(createResourcePanels)}</ResourcePanel>
      </>
    );
  });

  return <div className={className}>{pageDisplay}</div>;
};

export default TabPagePanel;
