import React, { PropsWithChildren } from 'react'
import './LessonPageHeader.scss'
import SubHeader from '../SubHeader/SubHeader'

interface LessonPageHeaderProps {
  headerTile: JSX.Element
  lessonNavigation?: React.ReactElement
}

/**
 * Renders the lesson page header for the R4R application. It contains the SDM navbar.
 *
 * @component
 * @param headerTile: Element to display the title of the page
 *
 * @example
 * <LessonPageHeader headerTile = {<div> title </div>} dropdownOptions = {["Selection1", "Selection2"]}/>
 *
 */
const LessonPageHeader = (props: PropsWithChildren<LessonPageHeaderProps>) => {
  const className = 'lesson-page-header'

  return (
    <div className={className} data-testid={'lesson-page-header'}>
      <SubHeader
        children={
          <div className={`${className}__actions`}>
            <div className={`${className}__header-tile`}> {props.headerTile}</div>
            {props.lessonNavigation}
          </div>
        }
      />
      {props.children}
    </div>
  )
}

export default LessonPageHeader
