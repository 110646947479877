import React, { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getFilterGroupsByModule, ModuleId } from 'api-library';
import './FilterByAccordionDropdown.scss';
import Accordion, { AccordionItem } from '../Accordion/Accordion';
import Popover from '../Popover/Popover';
import { Link } from 'react-router-dom';
import { Markup } from 'interweave';

interface FilterByAccordionDropdownProps {
  moduleId: string;
  filterType: string;
  dataType: string;
  initialFilterId: string;
  onFilterSelection: (filterGroup: string, filterGroupItem: string) => void;
  screenReaderDescription?: string;
}

const FilterByAccordionDropdown = ({
  moduleId,
  filterType,
  dataType,
  initialFilterId,
  onFilterSelection,
  screenReaderDescription
}: FilterByAccordionDropdownProps) => {
  const className = 'filter-dropdown';

  const [menuSelection, setMenuSelection] = useState(0);
  const [submenuSelection, setSubMenuSelection] = useState(0);
  const [popoverOpenState, setPopoverOpenState] = useState(false);
  const [prevModuleId, setPreviousModuleId] = useState(moduleId);
  const [prevTabId, setPreviousTabId] = useState(dataType);
  const [popoverHeader, setPopoverHeader] = useState('Filter By ' + filterType);
  const selectedSubMenuItem = useRef<HTMLAnchorElement>(null);
  const dropDownRef = useRef<HTMLButtonElement>(null);

  const getFilterGroup = () => {
    return useQuery(['getFilterGroupsByModule', moduleId], () => getFilterGroupsByModule(moduleId));
  };

  const { data: groupData } = getFilterGroup();

  const getGroupsFromArray = (groups: any[], uuidFieldName: string) => {
    if (prevModuleId !== moduleId || prevTabId !== dataType) {
      setPopoverHeader('Filter By ' + filterType);
      setPreviousModuleId(moduleId);
      setPreviousTabId(dataType);
    }
    const optionData = groups.map((group, index) => {
      const uuid = group[uuidFieldName];
      return {
        id: uuid,
        title: group.title,
        value: uuid,
        assetGroups: group.nestedGroups
      };
    });
    if (!initialFilterId) {
      setMenuSelection(0);
    }
    return [
      {
        id: '0',
        title: `All ${dataType}`,
        value: '',
        assetGroups: []
      },
      ...optionData
    ];
  };

  const options = useMemo(() => {
    if (groupData) {
      return moduleId === ModuleId.R4R_RTK.toString()
        ? getGroupsFromArray(groupData[0].nestedGroups, 'assetUuid')
        : getGroupsFromArray(groupData, 'groupId');
    } else {
      return [];
    }
  }, [groupData, initialFilterId, dataType, popoverHeader]);

  const handleSubMenu = function (
    menuIndex: number,
    submenuIndex: number,
    groupId: string,
    groupItemId: string,
    groupItemText: string
  ) {
    setPopoverHeader(groupItemText);
    setMenuSelection(menuIndex);
    setSubMenuSelection(submenuIndex);
    onFilterSelection(groupId, groupItemId);
    dropDownRef.current?.focus();
  };

  const handleAllOption = function (menuText: string) {
    if (menuText && options[0].title === menuText) {
      setPopoverHeader('Filter By ' + filterType);
      setPopoverOpenState(false);
      setMenuSelection(0);
      onFilterSelection('', '');
      dropDownRef.current?.focus();
    }
  };

  function handleKeyEvent(
    event: React.KeyboardEvent<HTMLAnchorElement>,
    menuIndex: number,
    subMenuIndex: number,
    accordionId: string,
    assetUuid: string,
    title: string
  ) {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSubMenu(menuIndex, subMenuIndex, accordionId, assetUuid, title);
      if (event.key === ' ') {
        setPopoverOpenState(!popoverOpenState);
      }
    }
  }

  return (
    <div className={className}>
      {options && options.length > 0 && (
        <Popover
          isPopupOpened={popoverOpenState}
          onOpenAutoFocus={(event) => {
            selectedSubMenuItem.current?.focus();
            dropDownRef.current?.focus();
          }}
          menuRef={dropDownRef}
          onOpenChange={(open) => {
            setPopoverOpenState(open);
          }}
          header={popoverHeader}
          ariaLabel={`${screenReaderDescription ?? 'Filter By ' + filterType} ${
            menuSelection === 0 ? options[menuSelection].title : `${popoverHeader}`?.replace(/<[^>]+>/g, '')
          } selected`}
          child={
            <Accordion
              isOpenInitially
              accordionItemIds={options.map((item) => item['title'])}
              defaultIndex={menuSelection}
            >
              <div
                className={className + '__accordion_menu'}
                onKeyDown={(event) =>
                  event.key === 'Escape' || event.key === 'Esc' ? dropDownRef.current?.focus() : ''
                }
              >
                {options.map((accordionItem, menuIndex) => {
                  return (
                    <>
                      {menuIndex === 0 && (
                        <AllLessonButton
                          id={accordionItem.id}
                          title={accordionItem.title}
                          onClick={(e) => {
                            handleAllOption(accordionItem.title);
                          }}
                          ariaSelected={menuSelection === 0}
                        />
                      )}
                      {menuIndex > 0 && (
                        <AccordionItem
                          id={`menubutton_${accordionItem.id}`}
                          key={accordionItem.id}
                          title={accordionItem.title}
                          disabled={accordionItem.assetGroups && accordionItem.assetGroups.length > 0}
                          ariaSelected={false}
                          ariaControls={`menu_${accordionItem.id}`}
                          data-testid={'page-' + accordionItem.id}
                        >
                          <ul
                            id={`menu_${accordionItem.id}`}
                            role={`menu`}
                            aria-labelledby={`menubutton_${accordionItem.id}`}
                          >
                            {accordionItem.assetGroups &&
                              accordionItem.assetGroups.map(
                                (
                                  assetGroupItem: { title: string; assetUuid: string },
                                  subMenuIndex: number
                                ) => {
                                  return (
                                    <li
                                      className={className + '__accordion_subItem'}
                                      role="presentation"
                                      onClick={(e) => {
                                        handleSubMenu(
                                          menuIndex,
                                          subMenuIndex,
                                          accordionItem.id,
                                          assetGroupItem.assetUuid,
                                          assetGroupItem.title
                                        );
                                        setPopoverOpenState(!popoverOpenState);
                                      }}
                                      aria-selected={
                                        menuSelection === menuIndex && submenuSelection === subMenuIndex
                                      }
                                    >
                                      <Link
                                        to={`/module/${moduleId}?filter=${accordionItem.id}&lessonId=${assetGroupItem.assetUuid}`}
                                        id={assetGroupItem.assetUuid}
                                        data-test-id={assetGroupItem.assetUuid}
                                        key={menuIndex}
                                        role={`menuitem`}
                                        aria-selected={
                                          menuSelection === menuIndex && submenuSelection === subMenuIndex
                                        }
                                        aria-label={`${assetGroupItem.title?.replace(
                                          /<[^>]+>/g,
                                          ''
                                        )} ${dataType} ${
                                          menuSelection === menuIndex && submenuSelection === subMenuIndex
                                            ? 'selected'
                                            : ''
                                        }`}
                                        onKeyDown={(event) => {
                                          handleKeyEvent(
                                            event,
                                            menuIndex,
                                            subMenuIndex,
                                            accordionItem.id,
                                            assetGroupItem.assetUuid,
                                            assetGroupItem.title
                                          );
                                        }}
                                        ref={
                                          menuSelection === menuIndex && submenuSelection === subMenuIndex
                                            ? selectedSubMenuItem
                                            : null
                                        }
                                      >
                                        <Markup content={assetGroupItem.title}></Markup>
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </AccordionItem>
                      )}
                    </>
                  );
                })}
              </div>
            </Accordion>
          }
        />
      )}
    </div>
  );
};

export default FilterByAccordionDropdown;

interface AllLessonButtonProps {
  id?: string;
  title: string;
  ariaSelected?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AllLessonButton = (props: AllLessonButtonProps) => {
  return (
    <button
      className="accordion-menu__trigger"
      id={`menubutton_${props.id}`}
      key={props.id}
      title={props.title}
      aria-selected={props.ariaSelected}
      data-testid={'page-' + props.id}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
};
