import React, { useRef } from 'react';
import './PaginationNavigation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Select from '../../Select/Select';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  changePage: (pageNumber: number) => void;
}

/**
 * PaginationNavigation component
 *
 * @component
 * @param {number} currentPage - current page on tab
 * @param {number} totalPages - maximum pages to be shown
 * @param {function} incrementPage - function to control navigating to next page
 * @param {decrementPage} - function to control navigating to previous page
 *
 * @example
 * <PaginationNavigation
 *     decrementPage={decrementPage}
 *     incrementPage={incrementPage}
 *     currentPage={1}
 *     totalPages={5}
 *    />
 *
 * @return arrow buttons and display of page numbers
 */

const PaginationNavigation = (props: PaginationProps) => {
  const className = 'pagination-bar';
  const leftActive = props.currentPage === 1;
  const rightActive = props.currentPage === props.totalPages;
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePageChange = (newPage: number) => {
    scrollToTop();
    props.changePage(newPage);
    inputRef.current?.blur();
  };

  const scrollToTop = () => {
    const mainContent = document.querySelector('#main-content') as HTMLElement;
    mainContent?.scrollIntoView(true);
    mainContent?.focus();
  };

  const leftClick = () => {
    if (props.currentPage > 1) {
      handlePageChange(props.currentPage - 1);
    }
  };

  const rightClick = () => {
    if (props.currentPage < props.totalPages) {
      handlePageChange(props.currentPage + 1);
    }
  };

  const handleInput = (selection: string) => {
    handlePageChange(Number(selection));
  };

  const generateNumberOptions = () => {
    const optionArray = Array();
    for (let i = 1; i <= props.totalPages; i++) {
      optionArray.push({ id: i, value: i, content: i });
    }
    return optionArray;
  };

  return (
    <div className={className} data-testid={className}>
      <button
        className={`${className}__nav-button left ${leftActive ? 'disable' : ''}`}
        aria-label={'Last Page'}
        data-testid={'left-page-nav'}
        disabled={leftActive}
        aria-disabled={leftActive}
        onClick={leftClick}
        type={'button'}
        id={'left-page-nav'}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <Select
        id={'page-input'}
        defaultText={String(props.currentPage)}
        initialSelected={props.currentPage - 1}
        options={generateNumberOptions()}
        onSelect={handleInput}
        dropdownStyles={{ width: 'max-content' }}
        dropdownOptionStyles={{ padding: '5px 10px' }}
        screenReaderDescription={`page selection`}
        screenReaderOptionText={` of ${props.totalPages} pages`}
      />
      of {props.totalPages}
      <button
        className={`${className}__nav-button right ${rightActive ? 'disable' : ''}`}
        aria-label={'Next Page'}
        data-testid={'right-page-nav'}
        disabled={rightActive}
        aria-disabled={rightActive}
        onClick={rightClick}
        type={'button'}
        id={'right-page-nav'}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default PaginationNavigation;
