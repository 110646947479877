import type { PropsWithChildren } from 'react';
import type { Card } from '../types';
import { getImageSource } from '../utilities';
import './Card.scss';

export interface CardProps {
  element: Card;
}

const className = 'lesson-card';

export default function Card({ children, element }: PropsWithChildren<CardProps>) {
  const getImageJsx = () => {
    if (element.image_path) {
      return (
        <div className={`${className}-image`}>
          <img src={getImageSource(element)} alt="" />
        </div>
      );
    }
  };

  return (
    <div className={className}>
      {getImageJsx()}
      <div className={`${className}-content`}>{children}</div>
    </div>
  );
}
