import type { PropsWithChildren } from 'react'
import type { CardContainer } from '../types'
import './CardContainer.scss'
import { Markup } from 'interweave'

const className = 'card-container'

export interface CardContainerProps {
  element: CardContainer
}

export default function CardContainer({ children, element }: PropsWithChildren<CardContainerProps>) {
  const [title, headerContent] = element.header

  const headerHtml = [
    title.html,
    '<ul>',
    headerContent.children
      .map((item: any) => {
        const [listItemHtml] = item.children
        return `<li>${listItemHtml.html}</li>`
      })
      .join(''),
    '</ul>'
  ].join('')

  return (
    <div className={className}>
      <Markup className={`${className}-header`} content={headerHtml} />
      <div className={`${className}-body`}>{children}</div>
    </div>
  )
}
