import { useMatch } from 'react-router-dom';
import type { Params } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ModuleId, getLesson } from 'api-library';

import LessonPageSubHeader from '../../../components/LessonPageSubHeader/LessonPageSubHeader';
import LessonPageHeader from '../../../components/LessonPageHeader/LessonPageHeader';
import { ModuleTile } from '../../../components/ModuleTile/ModuleTile';
import { inflate } from '../../../utilities/uuid';
import './LessonSubHeader.scss';
import LessonMegaDropdown from '../../../components/LessonMegaDropdown/LessonMegaDropdown';

export const LessonSubHeader = () => {
  const pathStr = window.location.pathname.includes('/tab/') ? '/lesson/:id/tab/:sequence' : '/lesson/:id';
  const match = useMatch({ path: pathStr });
  const params: Params = match?.params ?? {};
  const { id } = params;
  const lessonId = id && Number(id) ? parseInt(id, 10) : 1;
  const uuid = inflate(`r4r_lesson_`, String(lessonId)) as string;

  const { data: lessonData } = useQuery(['getLessonData', uuid], () => getLesson(uuid));

  const moduleId = lessonData?.moduleUuid;
  const tabs = lessonData ? lessonData.tabs : new Array<string>();
  const className = 'lesson-page-header';

  const getHeaderTile = () => {
    if (moduleId === ModuleId.R4R_UA) {
      return <div className={`${className}__additional-tile`}>Additional Lessons</div>;
    } else {
      return moduleId ? <ModuleTile moduleId={moduleId as ModuleId} /> : <div id={'test'}></div>;
    }
  };

  return (
    <LessonPageHeader
      headerTile={getHeaderTile()}
      lessonNavigation={
        <LessonMegaDropdown
          currentLesson={lessonData ? lessonData.title : ''}
          currentModule={moduleId ? moduleId : ''}
        />
      }
    >
      <div className={className + '__separator'} data-testid="separator"></div>
      {tabs.length > 1 && <LessonPageSubHeader lessonId={lessonId} tabs={tabs} />}
    </LessonPageHeader>
  );
};
