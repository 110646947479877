import cookies from 'js-cookie';
import { AssetApiData } from 'api-library';

declare global {
  interface Window {
    dumbleData?: {
      version?: string;
      user?: Record<string, unknown>;
      siteInfo?: Record<string, unknown>;
      page?: Record<string, unknown>;
    };
    _satellite?: {
      track: (label: string, props?: object) => void;
    };
  }
}

export const emitDirectCall = (event: string, details?: object) => {
  if (typeof window !== 'undefined' && window._satellite) {
    details ? window._satellite.track(event, details) : window._satellite.track(event);
  }
};

export function getAnalyticsEnv(): string {
  const hostname = window.location.hostname;
  switch (true) {
    case hostname.includes('localhost') || hostname.includes('.dev.'):
      return 'dev';
    case hostname.includes('.qa.'):
      return 'qa';
    case hostname.includes('.stage.'):
      return 'stage';
    case hostname.includes('.perf.'):
      return 'perf';
    default:
      return 'production';
  }
}

export function getPageNameFromData(data?: AssetApiData, baseName?: string | undefined): string | undefined {
  if (!baseName) {
    return undefined;
  } else if (!!data?.title) {
    return `${baseName} - ${data.title}`;
  } else if (!!data?.uuid) {
    return `${baseName} - ${data.uuid}`;
  } else {
    return baseName;
  }
}

export function updateDumbledataVersion() {
  if (!window.dumbleData) {
    window.dumbleData = {};
  }
  window.dumbleData.version = '1.0';
}

export function updateDumbledataUser() {
  if (!window.dumbleData) {
    window.dumbleData = { user: {} };
  }
  const sdmCookie = cookies.get('sdm_nav_ctx');
  const userData = JSON.parse(sdmCookie || '{}');
  const { role, user_id, orgId, parentOrgId } = userData;
  window.dumbleData.user = {
    userRole: role === 'educator' || role === 'teacher' ? 'teacher' : 'student',
    sdmId: user_id,
    parentOrgId,
    orgId
  };
}

export function updateDumbledataSiteInfo() {
  if (!window.dumbleData) {
    window.dumbleData = { siteInfo: {} };
  }
  window.dumbleData.siteInfo = {
    name: 'Ready4Reading',
    environment: getAnalyticsEnv(),
    audience: 'teacher'
  };
}

interface PageProps {
  data?: AssetApiData;
  baseName?: string;
  siteSection?: string;
  previousPageURL?: string;
}

export function updateDumbledataPage({ data, baseName, siteSection, previousPageURL }: PageProps) {
  let pageName = getPageNameFromData(data, baseName);
  pageName = pageName?.replace(/<[^>]+>/g, '');
  const destinationURL = window.location.href;
  if (!window.dumbleData) {
    window.dumbleData = { page: {} };
    updateDumbledataVersion();
    updateDumbledataSiteInfo();
    updateDumbledataUser();
  }

  if (
    !window.dumbleData.page?.destinationURL ||
    window.dumbleData.page.destinationURL !== window.location.href
  ) {
    window.dumbleData.page = {
      domain: window.location.hostname,
      destinationURL,
      isError: false,
      pageName,
      pageInfo: {
        breadcrumb: window.location.pathname
      },
      siteSection,
      previousPageURL
    };
    emitDirectCall('page-view');
  }
}
export default updateDumbledataPage;
