import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import { AblePlayer } from '../../components/AblePlayer/AblePlayer';
import AssetThumbnail from '../../components/AssetThumbnail/AssetThumbnail';

import { useAsset } from '../../queries/useAsset';
import updateDumbledataPage from '../../utilities/DumbleData';
import { inflate } from '../../utilities/uuid';
import { StoreAction, StoreContext } from '../../store/store';

export default function Video() {
  const { state, dispatch } = useContext(StoreContext);
  const { id } = useParams();
  const uuid = inflate('r4r_video_', id) as string;

  const { data, isLoading } = useAsset(uuid);

  const videoMp4Src = data?.url;
  const captionSrcUrl = data?.captionUrl ?? '';

  useEffect(() => {
    if (!!data) {
      const localURL = localStorage.getItem('previousPageURL') || undefined;
      updateDumbledataPage({
        data,
        baseName: 'Video',
        siteSection: 'Video Player',
        previousPageURL: state?.previousPageURL || localURL
      });
      dispatch({ type: StoreAction.SET_PREVIOUS_PAGE_URL, data: window.location.href });
      if (localURL) {
        localStorage.removeItem('previousPageURL');
      }
    }
  }, [data]);

  // TODO: placeholder for loading state
  if (isLoading) {
    return null;
  }

  if (!videoMp4Src) {
    return <AssetThumbnail type="video" />;
  }

  return (
    <AblePlayer
      playerId={'teacher-hub-video_' + uniqid()}
      videoMp4Src={videoMp4Src}
      captionSrcUrl={captionSrcUrl}
      descriptionSrcUrl={''}
      playerTheme="2020"
    />
  );
}
