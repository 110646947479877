import React from 'react';
import NavLink from '../NavLink/NavLink';
import SubHeader from '../SubHeader/SubHeader';
import './LessonPageSubHeader.scss';
import { emitDirectCall } from '../../utilities/DumbleData';

interface LessonPageSubHeaderProps {
  lessonId: number;
  tabs: Array<string>;
}

/**
 * Create the global sub header
 *
 * @example
 * <LessonPageSubHeader lessonId={2} tabs={5}/>
 *
 */

const LessonPageSubHeader = (props: LessonPageSubHeaderProps) => {
  const className = 'lesson-page-sub-header';
  return (
    <div className={className}>
      <SubHeader
        children={
          <div className={`${className}__nav-container`}>
            {props.tabs.map((tabName, index) => {
              const tabNumber = index + 1;
              const path = `/lesson/${props.lessonId}/tab/${tabNumber}`;
              return (
                <NavLink
                  key={tabName}
                  label={tabName}
                  onClick={() => emitDirectCall('lessonsNav', { name: tabName })}
                  path={path}
                  data-testid={'subheader-tab-' + tabName.replace(' ', '')}
                />
              );
            })}
          </div>
        }
      />
    </div>
  );
};

export default LessonPageSubHeader;
