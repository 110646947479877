import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Markup } from 'interweave';

import './ResourcePanel.scss';
import AssetThumbnail from '../AssetThumbnail/AssetThumbnail';
import type { AssetType } from '../AssetThumbnail/AssetThumbnail';
import { ReactComponent as Activity } from '../../assets/images/asset-icons/activity.svg';
import { ReactComponent as Assessment } from '../../assets/images/asset-icons/assessment.svg';
import { ReactComponent as Audio } from '../../assets/images/asset-icons/audio.svg';
import { ReactComponent as Downloadable } from '../../assets/images/asset-icons/downloadable.svg';
import { ReactComponent as EBook } from '../../assets/images/asset-icons/ebook.svg';
import { ReactComponent as Lesson } from '../../assets/images/asset-icons/lesson.svg';
import { ReactComponent as Video } from '../../assets/images/asset-icons/video.svg';
import { Link } from 'react-router-dom';
import { emitDirectCall } from '../../utilities/DumbleData';

const iconMap = new Map<AssetType | undefined, React.ElementType>([
  ['activity', Activity],
  ['assessment', Assessment],
  ['audio', Audio],
  ['downloadable', Downloadable],
  ['ebook', EBook],
  ['lesson', Lesson],
  ['video', Video]
]);

const className = 'resource-panel';

/**
 * ResourcePanel component
 *
 * @component
 * @param {PropsWithChildren<{}>} children - children of the component
 *
 * @example
 * <ResourcePanel>
 *   <ResourcePanelItem
 *     image={<img src="https://via.placeholder.com/150" alt="placeholder"/>}
 *     type="activity"
 *     title="Sample Title"
 *    />
 * </ResourcePanel>
 *
 * @return a that displays ResourcePanelItem cards in a grid
 */
function ResourcePanel({ children }: PropsWithChildren<{}>) {
  return <div className={className}>{children}</div>;
}

type ResourcePanelElement = 'button' | 'link';

interface ResourcePanelItemProps {
  image?: React.ReactNode;
  size?: 'small' | 'large';
  type: AssetType;
  title?: string;
  navigationUrl?: string;
  key?: number;
  innerRef?: React.Ref<any>;
  openNewTab?: boolean;
  moduleUuid?: string;
  tabName?: string;
  elementType?: ResourcePanelElement;
  onButtonClick?: () => void;
}

const itemClassName = `${className}-item`;
const imageClassName = `${itemClassName}-image`;
const itemClassNameSmall = `${itemClassName}__small`;
const contentClassName = `${itemClassName}-content`;
const iconClassName = `${itemClassName}-icon`;
const typeClassName = `${itemClassName}-type`;
const titleClassName = `${itemClassName}-title`;

/**
 * Component for creating a display panel
 *
 * @component
 * @param ResourcePanelItemProps details about the Display Panel
 * @param ResourcePanelItemProps.image the thumbnail image markup to be displayed on the card. If none is provided,
 *                                     a default thumbnail will be displayed based on the `type` prop
 * @param ResourcePanelItemProps.size determines whether the card is displayed in a small or large size
 * @param ResourcePanelItemProps.type the ActivityType of the card. Determines the card's icon, default thumbnail, and subheading
 * @param ResourcePanelItemProps.title the title text for the card
 *
 *
 * @example
 * <ResourcePanelItem
 *    size="small"
 *    image={<img src="https://via.placeholder.com/150" alt="Sample Image">}
 *    type="activity"
 *    title="Sample Title"
 *  />
 *
 * @return a component displaying a panel of cards using given data in a grid view
 */

function ResourcePanelItem({
  image,
  size = 'large',
  type,
  title,
  navigationUrl = '',
  key,
  innerRef,
  openNewTab,
  moduleUuid,
  tabName,
  elementType,
  onButtonClick
}: ResourcePanelItemProps) {
  const Icon = iconMap.get(type) || Activity;
  const validModuleClasses = ['r4r_module_001', 'r4r_module_002', 'r4r_module_003'];
  const handleResourceClick = () => {
    if (type === 'audio' || type === 'ebook' || type === 'video') {
      window.localStorage.setItem('previousPageURL', window.location.href);
    }
    if (location.href.includes('module')) {
      emitDirectCall('moduleResourceLaunch', { name: title, type: tabName });
    } else {
      emitDirectCall('resourceView', { name: title });
    }
  };

  const getResourcePanelInnerStructure = () => {
    return (
      <>
        <div className={imageClassName}>{image ?? <AssetThumbnail type={type} />}</div>
        <div className={contentClassName}>
          <div className={iconClassName}>
            <Icon aria-hidden />
          </div>
          <div>
            {type && <p className={typeClassName}>{type.toUpperCase()}</p>}
            {title && <Markup tagName="div" className={titleClassName} content={title} />}
          </div>
        </div>
      </>
    );
  };

  const getButtonOrLink = () => {
    if (elementType === 'button') {
      return (
        <button
          key={key}
          ref={innerRef}
          aria-label={`${title} open assignment dialog`}
          onClick={onButtonClick}
          data-testid={`resource-panel-item`}
          className={classNames(
            itemClassName,
            size === 'small' && itemClassNameSmall,
            moduleUuid && validModuleClasses.includes(moduleUuid) && `lesson-module--${moduleUuid}`
          )}
        >
          {getResourcePanelInnerStructure()}
        </button>
      );
    } else {
      return (
        <Link
          key={key}
          ref={innerRef}
          to={navigationUrl}
          onClick={handleResourceClick}
          target={openNewTab ? '_blank' : ''}
          data-testid={`resource-panel-item`}
          className={classNames(
            itemClassName,
            size === 'small' && itemClassNameSmall,
            moduleUuid && validModuleClasses.includes(moduleUuid) && `lesson-module--${moduleUuid}`
          )}
        >
          {getResourcePanelInnerStructure()}
        </Link>
      );
    }
  };

  return getButtonOrLink();
}

ResourcePanel.Item = ResourcePanelItem;
export { ResourcePanel, ResourcePanelItem };
