import Dialog from '../Dialog/Dialog';
import styles from './AssignmentDialog.module.scss';
import { Button } from '@scholastic/mockingjay';
import * as Dialogue from '@radix-ui/react-dialog';
import ManageAssignmentsDrawer from '../ManageAssignmentsDrawer/ManageAssignmentsDrawer';
import { useState } from 'react';
import cookies from 'js-cookie';
import { ModuleId } from 'api-library';
import { useAuth } from 'authentication-library';

interface AssignmentDialogProps {
  imgUrl: string | undefined;
  assignmentName: string;
  eReaderUrl: string;
  textId: string;
  onDialogChange?: (isChanged: boolean) => void;
  activeModule?: string;
}

const AssignmentDialog = ({
  imgUrl,
  assignmentName,
  eReaderUrl,
  textId,
  onDialogChange,
  activeModule
}: AssignmentDialogProps) => {
  const handleResourceClick = () => {
    window.localStorage.setItem('previousPageURL', window.location.href);
    window.open(eReaderUrl, '_blank', 'noreferrer');
  };

  const sdmCookie = cookies.get('sdm_nav_ctx');
  const userData = JSON.parse(sdmCookie || '{}');
  const { admin } = userData;
  const { entitlements } = useAuth();
  const [assignmentOpen, setAssignmentOpen] = useState<boolean>(false);

  const dialogStyles = {
    borderRadius: '16px'
  };

  const dialogContent = (
    <div className={styles.assignmentDialog} data-testid="assignmentDialog">
      <div className={styles.headerImgContainer}>
        <img className={styles.headerImg} src={imgUrl} alt-aria-label="ebook cover" alt="ebook cover" />
      </div>
      <Dialogue.Title className={styles.assignmentTitle}>{assignmentName}</Dialogue.Title>
      <div className={styles.buttonRow}>
        {!admin &&
          ((activeModule &&
            activeModule !== ModuleId.R4R_SRD.toString() &&
            activeModule !== ModuleId.R4R_RTK.toString()) ||
            entitlements.includes('R4R_System')) && (
            <ManageAssignmentsDrawer
              textId={textId}
              triggerButton={
                <Button
                  className={`styledButton secondary ${styles.assignmentButtons}`}
                  aria-haspopup="dialog"
                  aria-controls={assignmentOpen ? 'manage-assignments-drawer' : undefined}
                >
                  Manage Assignment
                </Button>
              }
              onDrawerChange={setAssignmentOpen}
              contentId="manage-assignments-drawer"
            />
          )}
        <Button className={`styledButton primary ${styles.assignmentButtons}`} onClick={handleResourceClick}>
          Read It Now
        </Button>
      </div>
    </div>
  );

  return (
    <Dialog content={dialogContent} dialogStyles={dialogStyles} onDialogChange={onDialogChange} isOpen />
  );
};

export default AssignmentDialog;
