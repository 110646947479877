import type { PropsWithChildren } from 'react';
import classNames from 'classnames';
import type { TextSet } from '../types';

import './TextSet.scss';
import { getImageSource } from '../utilities';

export interface TextSetProps {
  element: TextSet;
}

const className = 'text-set';

export default function TextSet({ children, element }: PropsWithChildren<TextSetProps>) {
  const { layout } = element;

  const modifierClassName = layout === 'vertical' ? `${className}__vertical` : undefined;
  const imageSource = getImageSource(element);

  return (
    <div className={classNames(className, modifierClassName)}>
      <div className={`${className}-image`}>
        <img src={imageSource} alt="" />
      </div>
      <div className={`${className}-content`}>{children}</div>
    </div>
  );
}
