export enum ElementTypes {
  HTML = 'html',
  HTML_IMAGE = 'html-image',
  HTML_LIST = 'html-list',
  HTML_LIST_ITEM = 'html-list-item',
  HTML_SECTION = 'html-section',
  CARD = 'card',
  CONTAINER = 'container',
  INLINE = 'inline',
  CARD_CONTAINER = 'card-container',
  MATERIAL_CARD = 'material-card',
  TABLE = 'table',
  TABLE_ROW = 'table-row',
  TABLE_CELL = 'table-cell',
  TEXT_SET = 'text-set'
}

export type ImagePathType = 'cloudfront' | 'url'

export type AssetType = 'PDF' | 'VIDEO' | 'AUDIO' | 'LESSON' | 'EBOOK' | 'ACTIVITY'

// TODO: Element type should be a union of all possible types
export type Element = any

export interface Card {
  type: ElementTypes.CARD
  layout: string
  image_alt_text: string
  image_path: string
  image_path_type: ImagePathType
}

export interface CardContainer {
  type: ElementTypes.CARD_CONTAINER
  body: MaterialCard[]
  header: Element[]
}
export interface Container {
  type: ElementTypes.CONTAINER
  theme: string
  children: Element[]
}

export interface HtmlImage {
  type: ElementTypes.HTML_IMAGE
  image_alt_text: string
  image_path: string
  image_path_type: ImagePathType
}

export interface MaterialCard {
  type: ElementTypes.MATERIAL_CARD
  html: string
  image_alt_text: string
  image_path: string
  image_path_type: ImagePathType
}

export interface Table {
  type: ElementTypes.TABLE
  children: TableRow[]
}

export interface TableRow {
  type: ElementTypes.TABLE_ROW
  is_header: boolean
  children: TableCell[]
}

export interface TableCell {
  type: ElementTypes.TABLE_CELL
  children: Element[]
}

export interface TextSet {
  type: ElementTypes.TEXT_SET
  layout: 'horizontal' | 'vertical'
  asset_uuid: string
  asset_type: AssetType
  image_path: string
  image_path_type: ImagePathType
  image_alt_text: string
  children: Element[]
}
