import PrimaryLayout from '../layouts/PrimaryLayout/PrimaryLayout';
import { CategoryTab, CategoryTabProps, Tab } from '../../components/CategoryTab/CategoryTab';
import Page1 from './Page1';
import TabPagePanel from './tabPages/TabPagePanel';
import { getSystemResourceAtLevel, ModuleId, SystemResourceCategory } from 'api-library';
import { useQuery } from 'react-query';
import { useAuth } from 'authentication-library';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getModuleIds } from '../../utilities/ModuleUtil';

function filterSystemResourcedata(
  systemResourcesData: SystemResourceCategory[] | undefined,
  modules: ModuleId[]
) {
  const filterData: SystemResourceCategory[] = [];
  systemResourcesData?.map((data) => {
    if (
      data.displayName === 'Guides' ||
      data.displayName === 'Additional Materials' ||
      (modules.includes(ModuleId.R4R_WBTP) && data.displayName === 'Additional Lessons')
    ) {
      filterData.push(data);
    }
  });
  return filterData;
}

const SystemResources = () => {
  const { modules } = useAuth();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const initialTab = searchParams.get('tab');
  const currentTab = initialTab ? Number(initialTab) - 1 : 0;

  const { data: systemResourcesData } = useQuery(['getSystemResourceAtLevel'], () =>
    getSystemResourceAtLevel(getModuleIds(modules), '1')
  );

  const filterSystemResourceData = filterSystemResourcedata(systemResourcesData, modules);
  const systemResourcesList = filterSystemResourceData ?? ([] as SystemResourceCategory[]);

  const getComponentByTabLabel = (systemResource: SystemResourceCategory): React.ReactElement => {
    if (
      systemResource.displayName === 'Guides' ||
      systemResource.displayName === 'Additional Materials' ||
      systemResource.displayName === 'Additional Lessons'
    ) {
      return <TabPagePanel systemResource={systemResource} />;
    } else {
      return Page1();
    }
  };

  const livePages: Tab[] = systemResourcesList.map((systemResource, index) => {
    return {
      id: index + 1,
      label: systemResource.displayName,
      component: getComponentByTabLabel(systemResource)
    };
  });

  const tabPages: CategoryTabProps = {
    tabs: livePages
  };

  const className = 'system-resources';

  return (
    <>
      <h1 className="page-title">System Resources</h1>
      <PrimaryLayout>
        <div className={className} data-testid={className}>
          {filterSystemResourceData && <CategoryTab {...tabPages} currentTab={currentTab} />}
        </div>
      </PrimaryLayout>
    </>
  );
};

export default SystemResources;
