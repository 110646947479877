import React, { useState, PropsWithChildren } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/minus.svg';
import './AccordionMenu.scss';

interface MenuContent {
  /** a unique id for this item */
  id: any;
  /** the text or React element that should be displayed */
  content: React.ReactNode;
  /** an array of sub-menu items */
  menuItems?: MenuContent[];
  /** a function to be called when this item is clicked */
  onClick?: VoidFunction;
}

interface AccordionMenuProps {
  /** the initial active menu item id */
  initialActive?: MenuContent['id'] | [MenuContent['id'], MenuContent['id']];
  /** an array of menu items */
  menuItems: MenuContent[];
}

interface NavItemProps {
  hasMenuItems?: boolean;
  isActive?: boolean;
  onClick?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
}

interface SubNavItemProps {
  isActive?: boolean;
  onClick?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
}

/**
 * Displays a menu with optional collapsible sub-menus
 *
 * @component
 * @param props
 *
 * @example
 *  <AccordionMenu
 *    initialActive={[1, 11]}
 *    menuItems={[
 *      {id: '0', content: <div>Single Menu Item</div>, onClick: () => console.log('clicked')},
 *      {id: '1', content: 'Expandable Menu Item', menuItems: [
 *        {id: '11', content: 'Sub-Menu Item'}
 *      ]}
 *    ]}
 *  />
 *
 * @return an Accordion menu with top-level and collapsible sub-menu items
 */

const className = 'accordion-menu';

export function AccordionMenu({ initialActive = null, menuItems }: AccordionMenuProps) {
  const activeItem = Array.isArray(initialActive) ? initialActive[0] : initialActive;
  const activeSubItem = Array.isArray(initialActive) ? initialActive[1] : null;
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(activeItem);
  const [openSubMenuIndex, setSubMenuIndex] = useState<number | null>(activeSubItem);

  return (
    <nav className={className}>
      {menuItems.map(({ content, id, menuItems: subMenuItems, onClick: handleItemClick }) => {
        const isActive = openMenuIndex === id;
        const hasMenuItems = subMenuItems && subMenuItems.length > 0;

        const handleMenuKeyDown = ({ key }: React.KeyboardEvent<HTMLElement>) => {
          if (key === ' ' || key === 'Enter') {
            handleMenuToggle();
          }
        };

        const handleMenuToggle = () => {
          const newIndex = id === openMenuIndex ? null : id;
          const newSubMenuIndex = hasMenuItems ? subMenuItems[0].id : 0;
          setOpenMenuIndex(newIndex);
          setSubMenuIndex(newSubMenuIndex);
          if (handleItemClick) {
            handleItemClick();
          }
        };

        return (
          <div className={`${className}-section`} key={id}>
            <NavItem
              hasMenuItems={hasMenuItems}
              isActive={isActive}
              onClick={handleMenuToggle}
              onKeyDown={handleMenuKeyDown}
            >
              {content}
            </NavItem>
            {hasMenuItems && isActive && (
              <div className={`${className}-sub-menu`}>
                {subMenuItems.map(({ content, id, onClick: handleClick }) => {
                  const handleSubMenuKeyDown = ({ key }: React.KeyboardEvent<HTMLElement>) => {
                    if (key === ' ' || key === 'Enter') {
                      setSubMenuIndex(id);
                      if (handleClick) {
                        handleClick();
                      }
                    }
                  };

                  const handleSubMenuItemClick = () => {
                    setSubMenuIndex(id);
                    if (handleClick) {
                      handleClick();
                    }
                  };
                  return (
                    <SubNavItem
                      key={id}
                      isActive={openSubMenuIndex === id}
                      onClick={handleSubMenuItemClick}
                      onKeyDown={handleSubMenuKeyDown}
                    >
                      {content}
                    </SubNavItem>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
}

function NavItem({
  children,
  hasMenuItems = false,
  isActive = false,
  onClick: handleClick,
  onKeyDown: handleKeyDown
}: PropsWithChildren<NavItemProps>) {
  const navItemClassName = `${className}-nav-item`;
  const activeClassName = `${navItemClassName} ${navItemClassName}__active`;
  const innerClassName = `${navItemClassName}__inner`;
  const iconClassName = `${navItemClassName}__icon`;

  return (
    <div
      className={isActive ? activeClassName : navItemClassName}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div className={innerClassName}>
        {children}
        {hasMenuItems && (
          <span className={iconClassName}>
            {isActive ? <MinusIcon aria-hidden /> : <PlusIcon aria-hidden />}
          </span>
        )}
      </div>
    </div>
  );
}

function SubNavItem({
  children,
  isActive = false,
  onClick: handleClick,
  onKeyDown: handleKeyDown
}: PropsWithChildren<SubNavItemProps>) {
  const subNavItemClassName = `${className}-sub-nav-item`;
  const activeClassName = `${subNavItemClassName} ${subNavItemClassName}__active`;

  return (
    <div
      className={isActive ? activeClassName : subNavItemClassName}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </div>
  );
}
