import { Dispatch, ReactNode, Reducer, createContext, useReducer } from 'react';

export enum StoreAction {
  SET_CURRENT_MODULE_TAB = 'SET_CURRENT_MODULE_TAB',
  SET_PREVIOUS_PAGE_URL = 'SET_PREVIOUS_PAGE_URL',
  SET_TOAST_ITEM = 'SET_TOAST_ITEM',
  DELETE_TOAST_ITEM = 'DELETE_TOAST_ITEM'
}

interface Action {
  type: StoreAction;
  data: any;
}

interface Store {
  [key: string]: any;
  dispatch: (action: Action) => void;
}

const initialState = {
  currentModuleTab: 1,
  previousPageURL: undefined,
  toastsItem: [],
  dispatch: () => null
};

const StoreContext = createContext<Store>(initialState);

const dispatchMiddleware = (dispatch: Dispatch<Action>) => {
  return (action: Action) => {
    dispatch(action);
  };
};

const reducer: Reducer<Store, Action> = (state, action) => {
  switch (action.type) {
    case StoreAction.SET_CURRENT_MODULE_TAB:
      return action.data === state.currentModuleTab
        ? state
        : {
            ...state,
            currentModuleTab: Number(action.data)
          };
    case StoreAction.SET_PREVIOUS_PAGE_URL:
      return action.data === state.previousPageURL
        ? state
        : {
            ...state,
            previousPageURL: action.data
          };
    case StoreAction.SET_TOAST_ITEM:
      return action.data === state.toastsItem
        ? state
        : {
            ...state,
            toastsItem: action.data
          };
    case StoreAction.DELETE_TOAST_ITEM:
      return {
        ...state,
        toastsItem: []
      };

    default:
      throw new Error();
  }
};

const StateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch: dispatchMiddleware(dispatch) }}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StateProvider, reducer };
