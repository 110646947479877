import React, { PropsWithChildren } from 'react';
import './BaseModuleTile.scss';
import r4rLogo from '../../../assets/images/r4r_logo.png';

interface ModuleTileProps {
  titles: JSX.Element | JSX.Element[];
  fullTitle: string;
}

/**
 * Component for asset overview information
 *
 * @component
 * @param children: Child content for the tile. Use for rendering the description content.
 * @param titles: Render slot for the tile's titles. Use the corresponding sub-components
 *                (ModuleTile.Title and ModuleTile.Subtitle) and render in the desited order.
 * @param fullTitle: Full title to be read by screenreader

 * @example
 *  <ModuleTile
 *    titles={
 *      <>
 *        <ModuleTile.Title>Lorem ipsum</ModuleTile.Title>
 *        <ModuleTile.SubTitle>Lorem ipsum</ModuleTile.SubTitle>
 *      </>
 *    }
 *    fullTitle={'Lorem ipsum Lorem ipsum'}
 *  >
 *    Lorem ipsum dolor sit amet
 *  </ModuleTile>
 *
 * @return a Module Tile that navigates to a location and provides information about the module.
 */

const className = 'module-tile';

export function BaseModuleTile({ children, titles, fullTitle }: PropsWithChildren<ModuleTileProps>) {
  return (
    <div className={className}>
      <span className={'sr-only'}>{fullTitle}</span>
      <div className={`${className}__titles`}>{titles}</div>
      {children && (
        <div className={`${className}__description`} data-testid="module-tile-description">
          {children}
        </div>
      )}
      <div className={`${className}__icon`}></div>
    </div>
  );
}

function Title({ children, gradient = ['#4a4a4a', '#4a4a4a'] }: { children: string; gradient?: string[] }) {
  const style = {
    backgroundImage: `linear-gradient(90deg, ${gradient[0]}, ${gradient[1]}`
  };

  return (
    <p className={`${className}-title`} style={style} aria-hidden={'true'}>
      {children}
    </p>
  );
}

function SubTitle({ children }: { children: string }) {
  const subtitleTextArray = children.split('');
  return (
    <p className={`${className}-subtitle`} data-testid="module-tile-subtitle" aria-hidden={'true'}>
      {subtitleTextArray.map((character, i) => {
        return (
          <span className={`${className}-subtitle__char`} key={i}>
            {character}
          </span>
        );
      })}
    </p>
  );
}

function TitleImage() {
  return (
    <div className={`${className}-subtitle`} data-testid="module-tile-subtitle">
      <img src={r4rLogo} alt="Scholastic Ready 4 Reading" height="28px"></img>
    </div>
  );
}

BaseModuleTile.Title = Title;
BaseModuleTile.SubTitle = SubTitle;
BaseModuleTile.TitleImage = TitleImage;
